import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InstrumentsType } from '../../../../Models/InstrumentsModel';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { ButtonAreaAdmin } from '../../../../Screens/AdminScreen/AdminScreenStyle';
import { useEditPiezometerReading } from '../../../../data/hooks/piezometer/use-edit-piezometer.reading.mutation';
import { useEditPluviometerReading } from '../../../../data/hooks/pluviometer/use-edit-pluviometer-reading.mutation';
import { useEditSurfaceMarkersReading } from '../../../../data/hooks/surface-marker/use-edit-surface-marker-reading.mutation';
import { useEditWaterLevelReading } from '../../../../data/hooks/water-level/use-edit-water-level-reading.mutation';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Buttons/ButtonsStyle';
import { DatePickerComponent } from '../../../DatePicker';
import GraphHeader from '../../../Graphs/Monitoring/Instrument/GraphHeader/GraphHeader';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import { TextAreaComponent } from '../../../TextAreaComponent';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../../Register/RegisterInstrumentStyle';
import { RegisterReadingsArea } from '../DataBaseStyles';
import InputCheckbox from '../../../V2/Molecules/InputCheckbox/InputCheckbox';

export function EditReadingsModal({
  edit,
  selectedType,
  createdReading,
  setCreatedReading,
  showModal,
  setShowModal
}: {
  edit: { status: boolean; data: any };
  selectedType: string;
  createdReading: boolean;
  setCreatedReading: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { structureId } = useParams();
  const [cote, setCote] = useState<string>('');
  const [reading, setReading] = useState<string>('');
  const [date, setDate] = useState<Date>(new Date());
  const [isDry, setIsDry] = useState<boolean>(false);
  const [rainfall, setRainfall] = useState<string>('');
  const [coordinateN, setCoordinateN] = useState<string>('');
  const [coordinateE, setCoordinateE] = useState<string>('');
  const [elevation, setElevation] = useState<string>('');
  const [observations, setObservations] = useState<string>('');
  const { t: translate } = useTranslation();
  const { editPiezometerReading } = useEditPiezometerReading();
  const { editReading: editPluviometerReading } = useEditPluviometerReading();
  const { editWaterLevelReading } = useEditWaterLevelReading();
  const { editSurfaceMarkerReading } = useEditSurfaceMarkersReading();
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };

  async function handleFetchPiezometers() {
    setCote(edit.data.cote);
    setReading(edit.data.reading);
    setIsDry(edit.data.isDry);
    setDate(new Date(edit.data.date));
    edit.data.observation === null
      ? setObservations('')
      : setObservations(edit.data.observation);
  }

  async function handleFetchPluviometers() {
    setDate(new Date(edit.data.date));
    setRainfall(edit.data.rainfall);
    edit.data.observation === null
      ? setObservations('')
      : setObservations(edit.data.observation);
  }

  async function handleFetchWaterLevels() {
    setDate(new Date(edit.data.date));
    setReading(edit.data.value);
    edit.data.observation === null
      ? setObservations('')
      : setObservations(edit.data.observation);
  }

  async function handleFetchSurfaceMarkers() {
    setDate(new Date(edit.data.reading.date));
    setCoordinateN(edit.data.reading.coordinateN);
    setCoordinateE(edit.data.reading.coordinateE);
    setElevation(edit.data.reading.elevation);
    edit.data.observation === null
      ? setObservations('')
      : setObservations(edit.data.observation);
  }

  const handleFetchSelectedInstrumentList = (type: string) => {
    const strategy = {
      piezometer: () => handleFetchPiezometers(),
      ina: () => handleFetchPiezometers(),
      pluviometer: () => handleFetchPluviometers(),
      waterlevel: () => handleFetchWaterLevels(),
      surfacemarker: () => handleFetchSurfaceMarkers()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  const handleEditPiezometer = async () => {
    if (cote || reading || isDry) {
      const response = await editPiezometerReading({
        variables: {
          structureInfo: structureInfo,
          data: {
            cote: cote ? Number(cote) : null,
            date: date,
            id: edit.data.readingId,
            isDry: isDry,
            observation: observations,
            reading: reading ? Number(reading) : null
          }
        }
      });
      toastifyLoading('Editando Leitura...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Editada Com Sucesso');
        setShowModal(false);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message ||
            translate(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  };

  const handleEditPluviometer = async () => {
    if (rainfall !== '') {
      const response = await editPluviometerReading({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: date,
            id: edit.data.readingId,
            observation: observations,
            rainfall: Number(rainfall)
          }
        }
      });
      toastifyLoading('Editando Leitura...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Editada Com Sucesso');
        setShowModal(false);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message ||
            translate(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  };

  const handleEditWaterLevel = async () => {
    if (reading !== '') {
      const response = await editWaterLevelReading({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: date,
            id: edit.data.readingId,
            observation: observations,
            value: Number(reading)
          }
        }
      });
      toastifyLoading('Editando Leitura...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Editada Com Sucesso');
        setShowModal(false);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message ||
            translate(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  };

  const handleEditSurfaceMarker = async () => {
    if (coordinateE !== '' && coordinateN !== '' && elevation !== '') {
      const response = await editSurfaceMarkerReading({
        variables: {
          structureInfo: structureInfo,
          data: {
            coordinateE: Number(coordinateE),
            coordinateN: Number(coordinateN),
            date: date,
            elevation: Number(elevation),
            id: edit.data.reading.id,
            observation: observations
          }
        }
      });
      toastifyLoading('Editando Leitura...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Leitura Editada Com Sucesso');
        setShowModal(false);
        setCreatedReading(!createdReading);
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message ||
            translate(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  };

  const handleEditReading = (type: string) => {
    const strategy = {
      piezometer: () => handleEditPiezometer(),
      ina: () => handleEditPiezometer(),
      pluviometer: () => handleEditPluviometer(),
      waterlevel: () => handleEditWaterLevel(),
      surfacemarker: () => handleEditSurfaceMarker()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  useEffect(() => {
    handleFetchSelectedInstrumentList(selectedType);
  }, []);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '50%',
          minWidth: 200,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '300px'
        }}
      >
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title={translate('EditReadings')}
          subtitle={selectedType}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RegisterReadingsArea>
            {(() => {
              switch (selectedType as InstrumentsType) {
                case 'Piezometer':
                  return (
                    <>
                      <DefaultInput
                        onChange={(event) => setCote(event.target.value)}
                        type="number"
                        placeholder={translate('Cote')}
                        value={cote}
                        disabled={!!reading || isDry}
                      />
                      <DefaultInput
                        onChange={(event) => setReading(event.target.value)}
                        type="number"
                        placeholder={translate('Reading')}
                        value={reading}
                        disabled={!!cote || isDry}
                      />
                      <div
                        style={{
                          width: '76%',
                          marginTop: 0,
                          marginBottom: 13,
                          display: 'flex'
                        }}
                      >
                        <InputCheckbox
                          label={translate('Dry')}
                          name="isDry"
                          onChange={() => {
                            setCote('');
                            setReading('');
                          }}
                          value={isDry}
                          setValue={() => setIsDry(!isDry)}
                        />
                      </div>
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'INA':
                  return (
                    <>
                      <DefaultInput
                        onChange={(event) => setCote(event.target.value)}
                        type="number"
                        placeholder={translate('Cote')}
                        value={cote}
                        disabled={!!reading || isDry}
                      />
                      <DefaultInput
                        onChange={(event) => setReading(event.target.value)}
                        type="number"
                        placeholder={translate('Reading')}
                        value={reading}
                        disabled={!!cote || isDry}
                      />
                      <div
                        style={{
                          width: '76%',
                          marginTop: 0,
                          marginBottom: 13,
                          display: 'flex'
                        }}
                      >
                        <InputCheckbox
                          label={translate('Dry')}
                          name="isDry"
                          onChange={() => {
                            setCote('');
                            setReading('');
                          }}
                          value={isDry}
                          setValue={() => setIsDry(!isDry)}
                        />
                      </div>
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'Pluviometer':
                  return (
                    <>
                      <DefaultInput
                        onChange={(event) => setRainfall(event.target.value)}
                        type="number"
                        placeholder={translate('Reading')}
                        value={rainfall}
                      />
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'WaterLevel':
                  return (
                    <>
                      <DefaultInput
                        onChange={(event) => setReading(event.target.value)}
                        type="number"
                        placeholder={translate('Reading')}
                        value={reading}
                      />
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'SurfaceMarker':
                  return (
                    <>
                      <DefaultInput
                        onChange={(event) => setCoordinateN(event.target.value)}
                        type="number"
                        placeholder={translate('N')}
                        value={coordinateN}
                      />
                      <DefaultInput
                        onChange={(event) => setCoordinateE(event.target.value)}
                        type="number"
                        placeholder={translate('E')}
                        value={coordinateE}
                      />
                      <DefaultInput
                        onChange={(event) => setElevation(event.target.value)}
                        type="number"
                        placeholder={translate('Elevation')}
                        value={elevation}
                      />
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </RegisterReadingsArea>
        </div>
        <ButtonAreaAdmin>
          <CancelButtonStyled onClick={() => setShowModal(false)}>
            {' '}
            {translate('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          <AdvanceButtonStyled
            onClick={() => {
              handleEditReading(selectedType);
            }}
          >
            {' '}
            {translate('Save').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
