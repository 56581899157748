import {
  BarElement,
  CategoryScale,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  registerables
} from 'chart.js';
import { Chart as ChartJS } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  AddDataSet,
  resetDataSet
} from '../../../../../Controllers/Graphs/SelectedGraphsDataSetController';
import {
  addAxis,
  removeAxis
} from '../../../../../Controllers/Graphs/SlectedGraphsAxisController';
import logo from '../../../../../Images/logoGS3transparent.svg';
import { formatDate } from '../../../../../utils/formatDate';
import { getRandomColor } from '../../../../../utils/getRandomColor';
import { DatePickerComponent } from '../../../../DatePicker';
import Button from '../../../../V2/Atoms/Button/Button';
import {
  DatePickerContainerStyle,
  GraphWindowInstrument
} from './InstrumentsChart.style';

export function InstrumentsChart({
  selectedGraphs,
  stateSelectedAxis,
  dispatchAxis,
  stateSelectedDataSet,
  dispatchDataSet,
  instrumentsCount,
  setInstrumentsCount,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}: any) {
  interface InstrumentCount {
    rainGauge: number;
    piezometer: number;
    shallowLandmark: number;
    reservoirWaterLevel: number;
    ina: number;
  }

  const chartRef = useRef<ChartJS>(null);

  const [raingGaugeFound, setRaingGaugeFound] = useState(false);
  const [piezometerFound, setPiezometerFound] = useState(false);
  const [shallowLandmarkFound, setShallowLandmarkFound] = useState(false);
  const [reservoirWaterLevelFound, setReservoirWaterLevelFound] =
    useState(false);
  const [inaFound, setInaFound] = useState(false);
  const [enableDrag, setEnableDrag] = useState(true);
  const { t } = useTranslation();

  const plugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color || 'white';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

  function resetInstrumentCount() {
    setInstrumentsCount({
      rainGauge: 0,
      piezometer: 0,
      shallowLandmark: 0,
      reservoirWaterLevel: 0,
      ina: 0
    });
  }

  function checkInstrumentWasFound(
    element: string,
    stateFound: boolean,
    setStateFound: Dispatch<boolean>,
    instrumentName: string,
    axis: any
  ) {
    if (element === instrumentName && !stateFound) {
      setStateFound(true);
      addAxis(dispatchAxis, axis);
    }
  }

  function checkIfInstrumentExists(
    setStateFound: Dispatch<boolean>,
    instrumentName: string
  ) {
    const elementExists = selectedGraphs.some(
      (e: any) => e.instrument === instrumentName
    );
    if (!elementExists) {
      setStateFound(false);
      removeAxis(dispatchAxis, instrumentName);
      if (instrumentName === 'shallowLandmark') {
        removeAxis(dispatchAxis, 'displacement');
      }
    }
  }

  function countInstruments(instrument: string, instrumentName: string) {
    if (instrument === instrumentName) {
      setInstrumentsCount((prevState: InstrumentCount) => {
        const updatedCount = { ...prevState };
        updatedCount[instrumentName as keyof InstrumentCount]++;
        return updatedCount;
      });
    }
  }

  const handleExportImage = () => {
    if (chartRef.current) {
      chartRef.current.canvas.toBlob((blob: any) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'chart.jpg';
        link.click();
      });
    }
  };

  useEffect(() => {
    resetDataSet(dispatchDataSet);
    resetInstrumentCount();

    selectedGraphs.map((e: any) => {
      checkInstrumentWasFound(
        e.instrument,
        raingGaugeFound,
        setRaingGaugeFound,
        'rainGauge',
        e.data.axis
      );
      checkInstrumentWasFound(
        e.instrument,
        piezometerFound,
        setPiezometerFound,
        'piezometer',
        e.data.axis
      );
      checkInstrumentWasFound(
        e.instrument,
        shallowLandmarkFound,
        setShallowLandmarkFound,
        'shallowLandmark',
        e.data.axis
      );
      checkInstrumentWasFound(
        e.instrument,
        reservoirWaterLevelFound,
        setReservoirWaterLevelFound,
        'reservoirWaterLevel',
        e.data.axis
      );
      checkInstrumentWasFound(
        e.instrument,
        inaFound,
        setInaFound,
        'ina',
        e.data.axis
      );

      countInstruments(e.instrument, 'rainGauge');
      countInstruments(e.instrument, 'piezometer');
      countInstruments(e.instrument, 'shallowLandmark');
      countInstruments(e.instrument, 'reservoirWaterLevel');
      countInstruments(e.instrument, 'ina');

      return null;
    });
    checkIfInstrumentExists(setRaingGaugeFound, 'rainGauge');
    checkIfInstrumentExists(setPiezometerFound, 'piezometer');
    checkIfInstrumentExists(setShallowLandmarkFound, 'shallowLandmark');
    checkIfInstrumentExists(setReservoirWaterLevelFound, 'reservoirWaterLevel');
    checkIfInstrumentExists(setInaFound, 'ina');
  }, [selectedGraphs]);

  useEffect(() => {
    selectedGraphs.map((e: any) => {
      e.data.dataSet.forEach((obj: any) => {
        if (
          e.instrument === 'piezometer' &&
          instrumentsCount.piezometer > 1 &&
          obj.label.endsWith('Nível piezométrico (m)')
        ) {
          AddDataSet(dispatchDataSet, obj);
        }

        if (e.instrument === 'piezometer' && instrumentsCount.piezometer <= 1) {
          AddDataSet(dispatchDataSet, obj);
        }

        if (
          e.instrument === 'rainGauge' ||
          e.instrument === 'shallowLandmark' ||
          e.instrument === 'reservoirWaterLevel' ||
          e.instrument === 'ina'
        ) {
          AddDataSet(dispatchDataSet, obj);
        }
      });
    });
  }, [instrumentsCount]);

  ChartJS.register(
    ...registerables,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin
  );

  const options = {
    responsive: true,
    interaction: {
      mode: 'x',
      intersect: false
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy'
        },
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          drag: {
            enabled: enableDrag,
            modifierKey: 'shift'
          },

          mode: 'yx'
        }
      },
      legend: {
        labels: {
          font: {
            size: 12
          },
          multiKeyBackground: getRandomColor('primary'),
          displayColors: true,
          boxHeight: 3
        },
        position: 'bottom',
        maxWidth: 300,
        onHover: () => {
          setEnableDrag(false);
        },
        onLeave: () => {
          setEnableDrag(true);
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        min: formatDate(startDate),
        max: formatDate(endDate),

        time: {
          displayFormats: {
            day: 'dd/MM/yy'
          }
        },
        title: {
          display: true,
          text: 'Tempo'
        },
        ticks: {
          source: 'auto',
          major: {
            enabled: true
          },
          font: function (context: any) {
            if (context.tick && context.tick.major) {
              return {
                weight: 900
              };
            }
          }
        },
        grid: {
          display: true,
          drawTicks: true,
          lineWidth: (context: any) =>
            context.tick && context.tick.major ? 3 : 1
        }
      },
      y: {
        type: 'linear',
        display: false
      },
      ...stateSelectedAxis
    }
  };

  useEffect(() => {
    chartRef.current?.update();
  }, [stateSelectedDataSet, stateSelectedAxis, startDate, endDate, enableDrag]);

  const data = {
    datasets: [...stateSelectedDataSet]
  };

  return (
    <GraphWindowInstrument>
      <DatePickerContainerStyle>
        <Button
          text={t('exportImage')}
          variant="primary"
          size="medium"
          onClick={handleExportImage}
        />
        <DatePickerComponent
          setStartDate={setStartDate}
          startDate={startDate}
          hasInterval
          setEndDate={setEndDate}
          endDate={endDate}
          dateText={t('InitialDate')}
        />
      </DatePickerContainerStyle>
      <Chart
        type={'line'}
        ref={chartRef}
        options={options}
        data={data}
        plugins={[plugin]}
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 65%'
        }}
      />
    </GraphWindowInstrument>
  );
}
