import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeleteActionPlanTeamMemberDocument,
  DeleteActionPlanTeamMemberMutation,
  DeleteActionPlanTeamMemberMutationVariables
} from '../../../../data/graphql/generated/deleteActionPlanTeamMember.mutation';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ActionPlanSaveMember from '../../Organisms/ActionPlanApprovalFlow copy/ActionPlanSaveMember';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { DeleteTeamMember, TeamMember } from './ActionPlanTeamPage.interface';
import {
  DivContentTeamMembers,
  DivTableTeam,
  DivTitle
} from './ActionPlanTeamPage.styles';
import {
  FindActionPlanDataDocument,
  FindActionPlanDataQuery,
  FindActionPlanDataQueryVariables
} from '../../../../data/graphql/query/generated/findActionPlanData.query';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../../@Types/types';
import {
  FindActionPlanMembersDocument,
  FindActionPlanMembersQuery
} from '../../../../data/graphql/query/generated/findActionPlanMembers.query';

const ActionPlanTeamPage = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { structureId, actionPlanId } = useParams();
  const [onSearch, setOnSearch] = useState('');
  const columns = [
    { key: 'name', label: translate('name') },
    { key: 'email', label: translate('email') }
  ];
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<DeleteTeamMember>({
    id: '',
    isOpen: false
  });

  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const { data: actionPlanData, loading: loadingMembers } = useQuery<
    FindActionPlanDataQuery,
    FindActionPlanDataQueryVariables
  >(FindActionPlanDataDocument, {
    variables: {
      actionPlanId: actionPlanId!
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const userResponsible =
    actionPlanData?.findActionPlanData.responsible?.id === userData.id;

  const actionPlanReproved =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'reproved';

  const actionPlanProgress =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'progress';

  const actionPlanlate =
    actionPlanData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'late';

  const [deleteActionPlanTeamMember] = useMutation<
    DeleteActionPlanTeamMemberMutation,
    DeleteActionPlanTeamMemberMutationVariables
  >(DeleteActionPlanTeamMemberDocument);

  const tableMembersData = actionPlanData?.findActionPlanData.teamMembers.map(
    (item) => ({
      id: item.id,
      name: item.name,
      email: item.email
    })
  );

  const filteredMembersData = tableMembersData?.filter(
    (item) =>
      item.name.toLowerCase().includes(onSearch) ||
      item.email.toLowerCase().includes(onSearch)
  );

  const handleDeleteMember = (idMember: string) => {
    deleteActionPlanTeamMember({
      variables: {
        actionPlanId: actionPlanId!,
        teamMemberId: idMember
      },
      onCompleted: () => {
        setDeleteModalIsOpen({ id: '', isOpen: false });
        toastfySuccess(
          `${translate('member')} ${translate('successfullyDeactivated')}`
        );
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
        setDeleteModalIsOpen({ id: '', isOpen: false });
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId!
          }
        }) as FindActionPlanDataQuery | undefined;
        const updatedData = {
          ...existingData,
          findActionPlanData: {
            ...existingData?.findActionPlanData,
            teamMembers: existingData?.findActionPlanData.teamMembers.filter(
              (member) => member.id !== idMember
            )
          }
        };
        cache.writeQuery({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId!
          },
          data: updatedData
        });

        const existingMembers = cache.readQuery({
          query: FindActionPlanMembersDocument,
          variables: {
            actionPlanId: actionPlanId!
          }
        }) as FindActionPlanMembersQuery | undefined;

        const updatedMembers = {
          ...existingMembers,
          findActionPlanMembers: {
            ...existingMembers?.findActionPlanMembers.filter((member) => {
              return member.id !== idMember;
            })
          }
        };

        cache.writeQuery({
          query: FindActionPlanMembersDocument,
          variables: {
            actionPlanId: actionPlanId!
          },
          data: updatedMembers
        });
      }
    });
  };

  const handleNavigateBack = () => {
    navigate(`/${structureId}/actionplan/${actionPlanId}`);
  };

  return (
    <SimpleContentTemplate
      loading={loadingMembers}
      title={
        <DivTitle>
          <Icon Icon={IoIosArrowBack} onClick={() => handleNavigateBack()} />
          <Text weight="bold" type="h2">
            {translate('team')}
          </Text>
        </DivTitle>
      }
      button={
        (actionPlanProgress || actionPlanReproved || actionPlanlate) &&
        userResponsible && (
          <Button
            variant={'primary'}
            text={`${translate('link')} ${translate('team')}`}
            size="medium"
            onClick={() => setSaveModalIsOpen(true)}
          />
        )
      }
      content={
        <DivContentTeamMembers>
          <DivTableTeam>
            <TableComponent
              columns={columns}
              onSearch={(event) => setOnSearch(event.toLowerCase())}
              tableData={filteredMembersData || []}
              actions={
                (actionPlanProgress || actionPlanReproved || actionPlanlate) &&
                userResponsible
                  ? [
                      {
                        name: `${translate('Delete')}`,
                        icon: <MdDelete />,
                        onClick: (element: TeamMember) => {
                          setDeleteModalIsOpen({
                            id: element.id,
                            isOpen: true
                          });
                        },
                        canShow: 'canDelete'
                      }
                    ]
                  : undefined
              }
            />
          </DivTableTeam>
          {saveModalIsOpen && (
            <ActionPlanSaveMember setSaveModalIsOpen={setSaveModalIsOpen} />
          )}

          {deleteModalIsOpen.isOpen && (
            <ModalConfirm
              onClose={() => setDeleteModalIsOpen({ id: '', isOpen: false })}
              onConfirm={() => handleDeleteMember(deleteModalIsOpen.id)}
              title={`${translate('Delete')} ${translate('member')}`}
              text={translate('deleteMemberText')}
            />
          )}
        </DivContentTeamMembers>
      }
    />
  );
};

export default ActionPlanTeamPage;
