import { Dispatch, useState } from 'react';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa6';
import AsideMenu from '../../../../../Controllers/AsideMenuController';
import { ASubTitle, LiSubMenu } from '../StyleInstrumentGraph';
import SubMenuGraphs from '../SubMenuGraphs/SubMenuGraphs';

interface SectionsSubMenuGraphProps {
  text: string;
  typesList: any;
  piezometer: any[];
  setPiezometer: Dispatch<any[]>;
  rainGauge: any[];
  setRainGauge: Dispatch<any[]>;
  ina: any[];
  setIna: Dispatch<any[]>;
  reservoirWaterLevel: any[];
  setReservoirWaterLevel: Dispatch<any[]>;
  shallowLandmark: any[];
  setShallowLandmark: Dispatch<any[]>;
  startDate: Date;
  endDate: Date;
  selectedGraphs: any[];
  setSelectedGraphs: Dispatch<any[]>;
}

export default function SectionsSubMenuGraph({
  text,
  typesList,
  piezometer,
  setPiezometer,
  rainGauge,
  setRainGauge,
  ina,
  setIna,
  reservoirWaterLevel,
  setReservoirWaterLevel,
  shallowLandmark,
  setShallowLandmark,
  startDate,
  endDate,
  selectedGraphs,
  setSelectedGraphs
}: SectionsSubMenuGraphProps) {
  const [showState, setShowState] = useState<boolean>(false);
  return (
    <>
      <LiSubMenu
        onClick={(e) =>
          AsideMenu.showMenu({ e, show: showState, setShow: setShowState })
        }
      >
        <ASubTitle>
          {showState ? <FaAngleDown /> : <FaAngleRight />} {text}{' '}
        </ASubTitle>
      </LiSubMenu>
      {showState && (
        <ul>
          {typesList.map(({ instruments, type }, i) => {
            const strategy = {
              piezometer: () => (
                <SubMenuGraphs
                  text={type}
                  state={piezometer}
                  setState={setPiezometer}
                  selectedGraphs={selectedGraphs}
                  setSelectedGraphs={setSelectedGraphs}
                  instruments={instruments}
                  startDate={startDate}
                  endDate={endDate}
                  key={i}
                />
              ),
              pluviometer: () => (
                <SubMenuGraphs
                  text={type}
                  state={rainGauge}
                  setState={setRainGauge}
                  selectedGraphs={selectedGraphs}
                  setSelectedGraphs={setSelectedGraphs}
                  instruments={instruments}
                  startDate={startDate}
                  endDate={endDate}
                  key={i}
                />
              ),
              ina: () => (
                <SubMenuGraphs
                  text={type}
                  state={ina}
                  setState={setIna}
                  selectedGraphs={selectedGraphs}
                  setSelectedGraphs={setSelectedGraphs}
                  instruments={instruments}
                  startDate={startDate}
                  endDate={endDate}
                  key={i}
                />
              ),
              waterlevel: () => (
                <SubMenuGraphs
                  text={type}
                  state={reservoirWaterLevel}
                  setState={setReservoirWaterLevel}
                  selectedGraphs={selectedGraphs}
                  setSelectedGraphs={setSelectedGraphs}
                  instruments={instruments}
                  startDate={startDate}
                  endDate={endDate}
                  key={i}
                />
              ),
              surfacemarker: () => (
                <SubMenuGraphs
                  text={type}
                  state={shallowLandmark}
                  setState={setShallowLandmark}
                  selectedGraphs={selectedGraphs}
                  setSelectedGraphs={setSelectedGraphs}
                  instruments={instruments}
                  startDate={startDate}
                  endDate={endDate}
                  key={i}
                />
              )
            };
            return strategy[type.toLowerCase() as keyof typeof strategy]();
          })}
        </ul>
      )}
    </>
  );
}
