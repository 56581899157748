import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import Select from 'react-select';
import {
  DivInputViewImage,
  InputFile,
  InspectionInputsDiv
} from '../../../../Components/Forms/FormInspectionsStyle';
import {
  DivSelectStyle,
  StyleInstrumentSelect
} from '../../../../Components/Selects/InstrumentSelect';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';
import { toastfyError, toastfySuccess } from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { ActionsRow } from '../../../../Screens/AdminScreen/AdminScreenStyle';
import {
  RadioCheboxArea,
  RadioCheck,
  RadioCheckbox
} from '../../../../Styles/Styles';
import { InspectionTextArea, RegisterArea } from '../../InspectionsStyle';
import {
  IdentifiesContainer,
  IdentifiesHeader,
  TextButtonAdd
} from './DamMassifStyles';
import { AnswerEnum } from '../../../../data/graphql/base-schema';
import ModalStep from '../../../../Components/V2/Molecules/ModalStep/ModalStep';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../../@Types/types';
import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { API_URL } from '../../../../utils/const';
import ModalConfirm from '../../../../Components/V2/Molecules/ModalConfirm/ModalConfirm';
import FileViewer from '../../../../Components/V2/Molecules/FileViewer/FileViewer';
import FileList from '../../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';
import Enums from '../../../../utils/enumns';

export function RepressionsIdentifies({
  setStepTwoStates,
  stepTwoStates,
  inspectionId,
  mssStructure,
  errorNextStep,
  setUpdateStepTwo
}: {
  setStepTwoStates: Dispatch<SetStateAction<any[]>>;
  stepTwoStates: any[];
  inspectionId: string;
  mssStructure: any;
  errorNextStep: boolean;
  setUpdateStepTwo: any;
}) {
  const { t }: any = useTranslation();
  const [repressions, setRepressions] = useState<any[]>([]);
  const [repressionsForm, setRepressionsForm] = useState<any>({
    local: null,
    identifiedAnomaly: null,
    observation: '',
    file: [],
    fileSent: false
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState([
    { checked: false },
    { checked: false }
  ]);
  const [img, setImg] = useState<FileType[]>([]);
  const [idDeleteRepression, setIdDeleteRepression] = useState<string>('');
  const [fileShowModal, setFileShowModal] = useState<FileType | null>(null);
  const [error, setError] = useState<boolean>(false);

  const { userData, getUserRoleByModule } = useContext(ProjectContext) as {
    userData: UserData;
    getUserRoleByModule: (moduleName: string) => string;
  };
  const { UserRoles } = Enums();
  const userRoleInModule = getUserRoleByModule('Inspections');
  const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: userData.token
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  const types = [
    {
      label: t('Deformation'),
      id: 'identifiedAnomaly',
      value: 'Deformation'
    },
    {
      label: t('DepressionSagging'),
      id: 'identifiedAnomaly',
      value: 'DepressionSagging'
    }
  ];
  const localTypes = [
    { label: t('Crista'), id: 'local', value: 'Crista' },
    { label: t('Bermas'), id: 'local', value: 'Bermas' },
    { label: t('Taludes'), id: 'local', value: 'Taludes' }
  ];

  const handleChangeCheckbox = (value: AnswerEnum, topic: string) => {
    const updatedStepStates = stepTwoStates.map((item: any) => {
      if (item.topic === topic) {
        return { ...item, answer: value };
      }

      return item;
    });
    setStepTwoStates(updatedStepStates);
    setCheckBox([
      { checked: value === AnswerEnum.Yes },
      { checked: value === AnswerEnum.No }
    ]);
  };

  const handleDelete = async (idRepressionFildes: string) => {
    const deleteResponse = await client.mutate({
      mutation: gql`
        mutation DeleteIdentifiedDeformation(
          $inspectionId: String!
          $identifiedDeformationId: String!
        ) {
          deleteIdentifiedDeformation(
            inspectionId: $inspectionId
            identifiedDeformationId: $identifiedDeformationId
          )
        }
      `,
      variables: {
        inspectionId: inspectionId,
        identifiedDeformationId: idRepressionFildes
      }
    });
    if (deleteResponse.data) {
      const filteredRepressions = repressions.filter(
        (e: any) => e.id !== idRepressionFildes
      );
      setRepressions(filteredRepressions);
      setStepTwoStates((prev) =>
        prev?.map((item) =>
          item.topic === 'Repressions'
            ? { ...item, fields: filteredRepressions }
            : item
        )
      );
      toastfySuccess(t('deletedSuccessfully'));
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
    }

    setIdDeleteRepression('');
  };

  const handleDeleteRepression = async (repression: any) => {
    const filteredRepression = repressions.filter(
      (item) => item !== repression
    );
    setRepressions(filteredRepression);
    setStepTwoStates((prev) =>
      prev?.map((item) =>
        item.topic === 'Repressions'
          ? { ...item, fields: filteredRepression }
          : item
      )
    );
  };

  const handleEdit = (repressions: any) => {
    setRepressionsForm(repressions);
    setShowModal(true);
    if (repressions.id) {
      setImg(repressions.file);
    } else {
      setImg(
        repressions.file.map((file: any) => {
          return {
            id: file.id,
            name: file.image,
            url: URL.createObjectURL(file),
            file: file
          };
        })
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setRepressionsForm({
      local: null,
      identifiedAnomaly: null,
      observation: '',
      file: [],
      fileSent: false
    });
    setImg([]);
  };

  async function postSituationAccess(formData: FormData) {
    const response = await fetch(
      `${API_URL}/inspection/${inspectionId}/images`,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `${userData.token}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error('Erro');
    }
  }

  const addRepressions = async () => {
    if (
      repressionsForm.local &&
      repressionsForm.identifiedAnomaly &&
      repressionsForm.observation &&
      repressionsForm.fileSent &&
      repressionsForm.file.length > 0
    ) {
      if (repressionsForm.id) {
        const editResponse = await client.mutate({
          mutation: gql`
            mutation UpdateIdentifiedDeformation(
              $newData: UpdateMssIdentifiedDeformationInput!
              $inspectionId: String!
            ) {
              updateIdentifiedDeformation(
                newData: $newData
                inspectionId: $inspectionId
              )
            }
          `,
          variables: {
            inspectionId: inspectionId,
            newData: {
              id: repressionsForm.id,
              local: repressionsForm.local,
              identifiedAnomaly: repressionsForm.identifiedAnomaly,
              observation: repressionsForm.observation
            }
          }
        });
        const newImages = repressionsForm.file.filter((file: any) => !file.id);
        if (newImages.length > 0) {
          const formData = new FormData();
          newImages.forEach((image: any) => {
            formData.append(
              `mss=identifiedDeformations=${repressionsForm.id}`,
              image.file ? image.file : image
            );
          });
          await postSituationAccess(formData);
        }

        if (editResponse.data) {
          const updatedRepressions = repressions.map((repression) => {
            if (repression.id === repressionsForm.id) {
              return {
                ...repressionsForm,
                local: repressionsForm.local,
                identifiedAnomaly: repressionsForm.identifiedAnomaly,
                observation: repressionsForm.observation,
                file: repressionsForm.file
              };
            }

            return repression;
          });
          setRepressions(updatedRepressions);
          setStepTwoStates((prev) =>
            prev?.map((item) =>
              item.topic === 'Repressions'
                ? { ...item, fields: updatedRepressions }
                : item
            )
          );
          setRepressionsForm({
            local: null,
            identifiedAnomaly: null,
            observation: '',
            file: [],
            fileSent: false
          });
          setImg([]);
          toastfySuccess(t('editedSuccessfully'));
          setError(false);
          setUpdateStepTwo((prev: boolean) => !prev);
          return true;
        } else {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          setError(true);
          return false;
        }
      } else {
        if (mssStructure) {
          const createResponse = await client.mutate({
            mutation: gql`
              mutation AddIdentifiedDeformation(
                $inspectionId: String!
                $newIdentifiedDeformation: MssIdentifiedDeformationsInput!
              ) {
                addIdentifiedDeformation(
                  inspectionId: $inspectionId
                  newIdentifiedDeformation: $newIdentifiedDeformation
                )
              }
            `,
            variables: {
              inspectionId: inspectionId,
              newIdentifiedDeformation: {
                local: repressionsForm.local,
                identifiedAnomaly: repressionsForm.identifiedAnomaly,
                observation: repressionsForm.observation
              }
            }
          });
          if (createResponse.data) {
            if (repressionsForm.file.length > 0) {
              const formData = new FormData();
              repressionsForm.file.forEach((image: any) => {
                formData.append(
                  `mss=identifiedDeformations=${createResponse.data.addIdentifiedDeformation}`,
                  image
                );
              });
              await postSituationAccess(formData);
            }

            const newRepression = {
              ...repressionsForm,
              id: createResponse.data.addIdentifiedDeformation
            };
            setRepressions([...repressions, newRepression]);
            setStepTwoStates((prev) =>
              prev?.map((item) =>
                item.topic === 'Repressions'
                  ? { ...item, fields: [...repressions, newRepression] }
                  : item
              )
            );
            setRepressionsForm({
              local: null,
              identifiedAnomaly: null,
              observation: '',
              file: [],
              fileSent: false
            });
            setImg([]);
            toastfySuccess(t('registeredSuccessfully'));
            setError(false);
            setUpdateStepTwo((prev: boolean) => !prev);
            return true;
          } else {
            toastfyError(t(ToastifyModel().toastifyMessage.error));
            setError(true);
            return false;
          }
        } else {
          setRepressions((prevState: any) => [...prevState, repressionsForm]);
          setStepTwoStates((prev) =>
            prev?.map((item) =>
              item.topic === 'Repressions'
                ? { ...item, fields: [...repressions, repressionsForm] }
                : item
            )
          );
          setRepressionsForm({
            local: null,
            identifiedAnomaly: null,
            observation: '',
            file: [],
            fileSent: false
          });
          setImg([]);
          setError(false);
          return true;
        }
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setError(true);
      return false;
    }
  };

  const handleChangeImage = (files: any) => {
    const updatedImg = img.concat(
      Array.from(files).map((file: any) => {
        return {
          id: null,
          name: file.name,
          url: URL.createObjectURL(file),
          file: file
        };
      })
    );
    setRepressionsForm((prevState: any) => ({
      ...prevState,
      fileSent: true,
      file: [...prevState.file, ...files]
    }));
    setImg(updatedImg);
  };

  const handleDeleteImage = async (imgSelect: FileType) => {
    if (imgSelect.id) {
      if (img.length <= 1) {
        toastfyError(t('needOneFile'));
        return;
      }

      const deleteResponse = await client.mutate({
        mutation: gql`
          mutation DeleteInspectionDataImage(
            $image: DeleteInspectionDataImageInput!
            $inspectionId: String!
          ) {
            deleteInspectionDataImage(
              image: $image
              inspectionId: $inspectionId
            )
          }
        `,
        variables: {
          inspectionId: inspectionId,
          image: {
            id: imgSelect.id,
            dataType: 'identifiedDeformation'
          }
        }
      });
      if (deleteResponse.data) {
        const filteredImages = img.filter(
          (image: FileType) => image.id !== imgSelect.id
        );
        setImg(filteredImages);
        setRepressionsForm((prevState: any) => ({
          ...prevState,
          file: filteredImages
        }));
        const updatedStepStates = stepTwoStates.map((item: any) => {
          if (item.topic === 'Repressions') {
            return {
              ...item,
              fields: item.fields.map((fieldItem: any) => {
                return {
                  ...fieldItem,
                  file: fieldItem.file.filter(
                    (file: any) => file.id !== imgSelect.id
                  )
                };
              })
            };
          }

          return item;
        });
        setStepTwoStates(updatedStepStates);
        return true;
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
        return false;
      }
    } else {
      const updatedImg = img.filter((item: FileType) => item !== imgSelect);
      setImg(updatedImg);
      const updateRepressionsForm = repressionsForm.file.filter(
        (file: any) => file !== imgSelect.file
      );
      setRepressionsForm((prevState: any) => ({
        ...prevState,
        file: updateRepressionsForm
      }));
      return true;
    }
  };

  useEffect(() => {
    if (stepTwoStates.length > 0) {
      stepTwoStates?.map((item: any) => {
        if (item.topic === 'Repressions') {
          setCheckBox([
            { checked: item.answer === AnswerEnum.Yes },
            { checked: item.answer === AnswerEnum.No }
          ]);
          setRepressions(
            item.fields.map((repression: any) => {
              let fileURLs: FileType[] = [];
              if (repression.file?.[0]?.image) {
                fileURLs = repression.file.map((file: any) => {
                  return {
                    id: file.id,
                    name: file.image,
                    url: `${API_URL}/${file.image}`
                  };
                });
              } else if (repression.fileSent) {
                if (repression.file.length > 0) {
                  fileURLs = repression.file.map((file: any) => {
                    return file;
                  });
                }
              }

              return {
                ...repression,
                file: fileURLs
              };
            }) || []
          );
        }
      });
    }
  }, [stepTwoStates]);

  return (
    <>
      <IdentifiesContainer>
        <IdentifiesHeader>
          <span>
            {t('RepressionsIdentifies')}
            <InspectionInputsDiv
              style={{
                justifyContent: 'flex-start',
                outline:
                  error && !checkBox.some((item) => item.checked)
                    ? '1px solid red'
                    : 'none'
              }}
            >
              <RadioCheboxArea style={{ left: 'unset' }}>
                <RadioCheckbox>
                  <input
                    type="radio"
                    name="RepressionsIdentifies"
                    id="yes_RepressionsIdentifies"
                    checked={checkBox[0].checked}
                    onChange={() =>
                      handleChangeCheckbox(AnswerEnum.Yes, 'Repressions')
                    }
                  />
                  <div className="transition"></div>
                </RadioCheckbox>
                <RadioCheck htmlFor="yes_RepressionsIdentifies">
                  {t('Yes')}
                </RadioCheck>
              </RadioCheboxArea>
              <RadioCheboxArea style={{ left: 'unset' }}>
                <RadioCheckbox>
                  <input
                    type="radio"
                    name="RepressionsIdentifies"
                    id="no_RepressionsIdentifies"
                    checked={checkBox[1].checked}
                    onChange={() =>
                      handleChangeCheckbox(AnswerEnum.No, 'Repressions')
                    }
                  />
                  <div className="transition"></div>
                </RadioCheckbox>
                <RadioCheck htmlFor="no_RepressionsIdentifies">
                  {t('No')}
                </RadioCheck>
              </RadioCheboxArea>
            </InspectionInputsDiv>
          </span>
          {checkBox[0].checked && userRoleInModule !== UserRoles.Viewer && (
            <TextButtonAdd
              style={{
                outline:
                  (error && checkBox[0].checked && repressions.length <= 0) ||
                  errorNextStep
                    ? '2px solid red'
                    : 'none'
              }}
              onClick={() => {
                setShowModal(!showModal);
                setRepressionsForm((prev: any) => ({ ...prev }));
              }}
            >
              {t('Add')}
            </TextButtonAdd>
          )}
        </IdentifiesHeader>
        {checkBox[0].checked && (
          <TableComponentStyle
            style={{
              outline:
                (error && checkBox[0].checked && repressions.length <= 0) ||
                errorNextStep
                  ? '2px solid red'
                  : 'none'
            }}
          >
            <thead>
              <tr>
                <th>{t('Local')}</th>
                <th>{t('AnomalyObserved')}</th>
                <th>{t('Observation')}</th>
                <th>{t('Image')}</th>
                {userRoleInModule !== UserRoles.Viewer && (
                  <th>{t('actions')}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {repressions.map((repression, index) => (
                <tr key={index}>
                  <td>{t(repression.local)}</td>
                  <td>{t(repression.identifiedAnomaly)}</td>
                  <td>
                    {repression.observation && repression.observation.length > 0
                      ? `${repression.observation.slice(0, 9)}...`
                      : '-'}
                  </td>
                  <td>
                    {repression.file?.map((file: any, index: number) => {
                      return (
                        <img
                          key={index}
                          src={file.url || URL.createObjectURL(file)}
                          alt="repression"
                          style={{
                            width: '50px',
                            height: '50px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setFileShowModal(file);
                          }}
                        />
                      );
                    }) || '-'}
                  </td>
                  {userRoleInModule !== UserRoles.Viewer && (
                    <td>
                      <ActionsRow>
                        {repression.id && (
                          <BiSolidPencil
                            onClick={() => {
                              handleEdit(repression);
                            }}
                          />
                        )}
                        <MdDelete
                          onClick={() =>
                            repression.id
                              ? setIdDeleteRepression(repression.id)
                              : handleDeleteRepression(repression)
                          }
                        />
                      </ActionsRow>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </TableComponentStyle>
        )}
        {showModal && (
          <ModalStep
            width={30}
            onClose={handleCloseModal}
            title={
              repressionsForm.id
                ? `${t('Edit')} ${t('Repressions')}`
                : t('AddRepressions')
            }
            steps={[
              {
                name: '',
                component: (
                  <>
                    <RegisterArea>
                      <DivSelectStyle
                        style={{ width: '320px' }}
                        error={error && !repressionsForm.local}
                      >
                        <Select
                          placeholder={t('Local')}
                          options={localTypes}
                          styles={StyleInstrumentSelect}
                          hideSelectedOptions
                          maxMenuHeight={200}
                          name="local"
                          value={localTypes.find(
                            (type) => type.value === repressionsForm.local
                          )}
                          onChange={(event: any) => {
                            setRepressionsForm((prev: any) => ({
                              ...prev,
                              local: event.value
                            }));
                          }}
                        />
                      </DivSelectStyle>
                      <DivSelectStyle
                        style={{ width: '320px' }}
                        error={error && !repressionsForm.identifiedAnomaly}
                      >
                        <Select
                          placeholder={t('AnomalyObserved')}
                          options={types}
                          styles={StyleInstrumentSelect}
                          hideSelectedOptions
                          maxMenuHeight={200}
                          name="identifiedAnomaly"
                          value={types.find(
                            (type) =>
                              type.value === repressionsForm.identifiedAnomaly
                          )}
                          onChange={(event: any) => {
                            setRepressionsForm((prev: any) => ({
                              ...prev,
                              identifiedAnomaly: event.value
                            }));
                          }}
                        />
                      </DivSelectStyle>
                      <InspectionTextArea
                        style={{
                          width: '293px',
                          height: '100px',
                          minHeight: '80px',
                          outline:
                            error && repressionsForm.observation === ''
                              ? '1px solid red'
                              : 'none'
                        }}
                        placeholder={t('Observation')}
                        spellCheck={false}
                        name="observation"
                        value={repressionsForm.observation}
                        onChange={(event: any) => {
                          setRepressionsForm((prev: any) => ({
                            ...prev,
                            observation: event.target.value
                          }));
                        }}
                      />
                      <DivInputViewImage>
                        <InputFile>
                          <input
                            type="file"
                            name="image"
                            id={'File_crack'}
                            accept=".png, .jpg, .jpeg"
                            multiple
                            onChange={(event) => {
                              handleChangeImage(event.target.files);
                            }}
                            style={{
                              outline:
                                error && img.length <= 0
                                  ? '1px solid red'
                                  : 'none'
                            }}
                          />
                        </InputFile>
                        {img.length > 0 && (
                          <FileList
                            files={img}
                            onDelete={(img: FileType) => {
                              handleDeleteImage(img);
                            }}
                          />
                        )}
                      </DivInputViewImage>
                    </RegisterArea>
                  </>
                ),
                onNext: () => addRepressions()
              }
            ]}
          />
        )}
      </IdentifiesContainer>

      {idDeleteRepression && (
        <ModalConfirm
          title={`${t('Delete')} ${t('Repression')}`}
          text={t('ConfirmDelete')}
          subtitle={t('ActionCannotBeUndone')}
          onConfirm={() => handleDelete(idDeleteRepression)}
          onClose={() => setIdDeleteRepression('')}
        />
      )}

      {fileShowModal && (
        <FileViewer
          files={[fileShowModal]}
          selectedFile={fileShowModal}
          setSelectedFile={setFileShowModal}
          onClose={() => {
            setFileShowModal(null);
          }}
        />
      )}
    </>
  );
}
