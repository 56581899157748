import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingByPiezometersPagQueryVariables = Types.Exact<{
  sortInfo?: Types.InputMaybe<Array<Types.SortInput> | Types.SortInput>;
  pageInfo: Types.PageInput;
  instrumentType: Types.InstrumentTypeEnum;
  structureInfo: Types.StructureInfoInput;
  filters?: Types.InputMaybe<Types.FilterInput>;
}>;


export type ListReadingByPiezometersPagQuery = { __typename?: 'Query', listReadingByPiezometersPag: { __typename?: 'PaginatedPiezometerInaReading', count: number, nodes: Array<{ __typename?: 'ListPiezometerInaReadingType', activationDate?: Date | null, deactivationDate?: Date | null, name: string, readingType: string, installationDate?: Date | null, installLocation?: string | null, operationalStatus: Types.InstrumentStatusEnum, coordinateE?: number | null, coordinateN?: number | null, instrumentId: string, bottomCote?: number | null, topCote?: number | null, readingId: string, date?: Date | null, structureType?: string | null, cote?: number | null, reading?: number | null, isDry?: boolean | null, observation?: string | null, isUTM: boolean }>, pageInfo: { __typename?: 'PageInfo', page: number, offset: number, limit: number, totalPages: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const ListReadingByPiezometersPagDocument = gql`
    query ListReadingByPiezometersPag($sortInfo: [SortInput!], $pageInfo: PageInput!, $instrumentType: InstrumentTypeEnum!, $structureInfo: StructureInfoInput!, $filters: FilterInput) {
  listReadingByPiezometersPag(
    sortInfo: $sortInfo
    pageInfo: $pageInfo
    instrumentType: $instrumentType
    structureInfo: $structureInfo
    filters: $filters
  ) {
    nodes {
      activationDate
      deactivationDate
      name
      readingType
      installationDate
      installLocation
      operationalStatus
      coordinateE
      coordinateN
      instrumentId
      bottomCote
      topCote
      readingId
      date
      structureType
      cote
      reading
      isDry
      observation
      isUTM
    }
    count
    pageInfo {
      page
      offset
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type ListReadingByPiezometersPagQueryResult = Apollo.QueryResult<ListReadingByPiezometersPagQuery, ListReadingByPiezometersPagQueryVariables>;