import styled from 'styled-components';
export const HolderIconAndValueTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
`;

export const DivContentTable = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DivTable = styled.div`
  width: 90%;
`;
