import styled from 'styled-components';

export const DivCompanyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  gap: 60px;
  flex-wrap: wrap;
`;

export const DivBaseInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

export const DivCards = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DivButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const MapArea = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-left: 15px;
`;

export const DivContainerFooterData = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  padding-bottom: 25px;
  padding: 15px 0;
  box-sizing: border-box;
`;
