import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const Content = styled.div`
  width: 35%;
  height: 30%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const Head = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
  border-bottom: 1px solid #967442;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  padding: 15px;
  margin-bottom: 25px;
  box-sizing: border-box;
  justify-content: end;
  gap: 10px;
`;
