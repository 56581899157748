import { IoIosClose } from 'react-icons/io';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { ViewModalProps } from './ViewModal.interface';
import {
  DivContainerViewModalBody,
  DivModalContainer,
  DivViewModal,
  DivViewModalContent,
  DivViewModalHeader
} from './ViewModal.styles';

const ViewModal = ({
  width,
  height,
  title,
  onClose,
  style,
  component
}: ViewModalProps) => {
  return (
    <DivModalContainer>
      <DivViewModal style={style} width={width} height={height}>
        <DivViewModalContent>
          <DivViewModalHeader>
            <Text type="h4" size="medium">
              {title}
            </Text>
            <Icon
              Icon={IoIosClose}
              variant="large"
              onClick={() => {
                onClose();
              }}
            />
          </DivViewModalHeader>
          <DivContainerViewModalBody>{component}</DivContainerViewModalBody>
        </DivViewModalContent>
      </DivViewModal>
    </DivModalContainer>
  );
};

export default ViewModal;
