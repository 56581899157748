import { getMonth, getYear } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import i18next from 'i18next';
import { useState } from 'react';
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps
} from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { DatePickerInputProps } from './DatepickerInput.interfaces';
import {
  DatepickerInputContainer,
  DivDatepickerContainer,
  DivInputDatepickerFlex,
  ErrorStyled,
  InputContainer,
  ReactDatePickerContainer
} from './DatepickerInput.styles';

//TODO refatorar componente
const DatepickerInput = ({
  onChange,
  placeholder,
  time,
  enableMinMaxDateSelection,
  minDate,
  maxDate,
  setDateValue,
  error,
  errorMessage,
  control,
  name,
  dateValue,
  width = '120px',
  disabled,
  label,
  required
}: DatePickerInputProps) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(dateValue || null);
  const currentYear = getYear(new Date());
  const years = Array.from({ length: 19 }, (_, i) => currentYear - 8 + i);
  const months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December')
  ];

  const yearOptions = years.map((option) => ({
    value: option,
    label: option.toString()
  }));

  const monthOptions = months.map((option) => ({
    value: option,
    label: option.toString()
  }));

  const handleChangeDate = (event: Date | null) => {
    setDate(event);
    if (setDateValue) {
      setDateValue(event);
    }

    if (onChange) {
      onChange(event || undefined);
    }
  };

  const hasTimeSelected = (date: any) => {
    return date && (date.getHours() !== 0 || date.getMinutes() !== 0);
  };

  const datePickerProps = {
    showIcon: true,
    minDate: enableMinMaxDateSelection ? minDate : undefined,
    maxDate: enableMinMaxDateSelection ? maxDate : undefined,
    timeInputLabel: time
      ? i18next.language.includes('pt')
        ? 'Tempo:'
        : 'Time:'
      : '',
    showTimeInput: time,
    locale: i18next.language.includes('pt') ? ptBR : enUS,
    dateFormat: hasTimeSelected(date)
      ? i18next.language.includes('pt')
        ? 'dd/MM/yyyy HH:mm'
        : 'MM/dd/yyyy HH:mm'
      : i18next.language.includes('pt')
        ? 'dd/MM/yyyy'
        : 'MM/dd/yyyy',
    placeholderText: placeholder,
    renderCustomHeader: ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth
    }: ReactDatePickerCustomHeaderProps) => (
      <DivDatepickerContainer>
        <Icon Icon={MdKeyboardArrowLeft} onClick={decreaseMonth} />
        <InputSelectSearch
          label=""
          name="years"
          options={yearOptions}
          hideSelectedOptions
          width="50px"
          value={{
            value: getYear(date),
            label: getYear(date).toString()
          }}
          onChange={(event) => changeYear(Number(event.value))}
        />
        <InputSelectSearch
          label=""
          name="months"
          options={monthOptions}
          hideSelectedOptions
          width="90px"
          value={{
            value: months[getMonth(date)],
            label: months[getMonth(date)].toString()
          }}
          onChange={(event) => changeMonth(months.indexOf(event.value))}
        />
        <Icon Icon={MdKeyboardArrowRight} onClick={increaseMonth} />
      </DivDatepickerContainer>
    ),
    selected: date,
    onChange: handleChangeDate,
    disabled: disabled
  };

  return (
    <InputContainer>
      <DivInputDatepickerFlex>
        <Text
          type="label"
          color={disabled ? 'disabled' : 'brown'}
          htmlFor={name}
          error={error}
        >
          {label} {required && '*'}
        </Text>
        <DatepickerInputContainer>
          <ReactDatePickerContainer
            width={width}
            error={error}
            disabled={disabled}
          >
            {control ? (
              <Controller
                control={control}
                name={name}
                defaultValue={null}
                render={({ field }) => (
                  <ReactDatePicker
                    {...datePickerProps}
                    selected={field.value || date}
                    onChange={(date) => {
                      field.onChange(date);
                      handleChangeDate(date);
                    }}
                  />
                )}
              />
            ) : (
              <ReactDatePicker {...datePickerProps} />
            )}
          </ReactDatePickerContainer>
        </DatepickerInputContainer>
      </DivInputDatepickerFlex>
      {error ? <ErrorStyled>{errorMessage}</ErrorStyled> : <ErrorStyled />}
    </InputContainer>
  );
};

export default DatepickerInput;
