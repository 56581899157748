import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileList from '../../Molecules/FileList/FileList';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputFile from '../../Molecules/InputFile/InputFile';
import { DivContainer } from './InputFileWithListStyled';

interface InputFileWithListProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  error: boolean;
  errorMessage?: string;
  accept: string;
  label: string;
  files: FileType[];
  multiple?: boolean;
  onDelete: (file: FileType) => void;
  register?: UseFormRegister<any>;
  value?: string;
  canDelete?: boolean;
}

const InputFileWithList = ({
  onChange,
  name,
  error,
  errorMessage,
  accept,
  label,
  files,
  multiple,
  onDelete,
  register,
  value,
  canDelete
}: InputFileWithListProps) => {
  const { t } = useTranslation();

  return (
    <DivContainer>
      <InputFile
        value={value}
        register={register}
        onChange={onChange}
        name={name}
        error={error}
        accept={accept}
        multiple={multiple}
        label={t(label)}
        errorMessage={errorMessage}
      />
      {files.length > 0 && (
        <FileList files={files} onDelete={onDelete} canDelete={canDelete} />
      )}
    </DivContainer>
  );
};

export default InputFileWithList;
