/* eslint-disable react/react-in-jsx-scope */
import {
  HolderInstrumentInputRightSide,
  HolderInstrumentInputsLeftSide,
  RegisterInstrumentInputs,
  RegisterNumericStyled
} from './RegisterInstrumentStyle';
import InstrumentsModel from '../../../Models/InstrumentsModel';
import Toggles from '../../Buttons/Toggles/Toggles';
import { DatePickerComponent } from '../../DatePicker';
import { useTranslation } from 'react-i18next';
import { RegisterBasicInfosInterface } from '../../../@Types/Instruments/instruments';
import InstrumentSelects from '../../Selects/Instruments/InstrumentsSelects';
import { DefaultInput } from '../../Inputs/InputsStyle';
import { useEffect } from 'react';

export const RegisterBasicInfos = (props: RegisterBasicInfosInterface) => {
  const { t } = useTranslation();

  const {
    setReadingModeSelected,
    installationDate,
    setInstallDate,
    activationDate,
    setActivationDate,
    setInstallationLocationSelected,
    readingModeSelected,
    installationLocationSelected,
    setBasicInfos,
    basicInfos,
    basicInfosError
  } = props;

  useEffect(() => {
    setBasicInfos('activationDate', new Date(activationDate).toISOString());
    setBasicInfos('installationDate', new Date(installationDate).toISOString());
  }, [activationDate, installationDate]);

  return (
    <RegisterInstrumentInputs>
      <HolderInstrumentInputsLeftSide>
        <DefaultInput
          style={{
            width: '90%',
            padding: '10px 10px 2px 5px',
            border: 'none',
            borderBottom: '1px solid',
            margin: '0px'
          }}
          onChange={(e) => {
            setBasicInfos('name', e.target.value);
          }}
          placeholder={t('name')}
          value={basicInfos.name}
          error={!basicInfos.name && basicInfosError}
        />
        <RegisterNumericStyled
          maxLength={30}
          onChange={(e) => {
            setBasicInfos(
              'coordinateN',
              Number(e.target.value.replace(/,/g, ''))
            );
          }}
          placeholder={`${t('Coordinate')} N`}
          value={basicInfos.coordinateN}
          error={!basicInfos.coordinateN && basicInfosError}
        />

        <RegisterNumericStyled
          maxLength={30}
          placeholder={`${t('Coordinate')} E`}
          onChange={(e) => {
            setBasicInfos(
              'coordinateE',
              Number(e.target.value.replace(/,/g, ''))
            );
          }}
          value={basicInfos.coordinateE}
          error={!basicInfos.coordinateE && basicInfosError}
        />
        <div style={{ width: '90%' }}>
          {Toggles.ToggleStatus({
            check: basicInfos.isUTM,
            setCheck: (value) => setBasicInfos('isUTM', value),
            key: 'isUTM',
            updateValue: setBasicInfos,
            text: 'Coordenadas em UTM'
          })}
        </div>
        {InstrumentSelects.InstrumentType({
          width: 93.7,
          placeholder: t('InstallationLocation'),
          options: InstrumentsModel().instalationLocale,
          setSelected: setInstallationLocationSelected,
          selected: installationLocationSelected,
          key: 'installLocation',
          updateValue: setBasicInfos
        })}
      </HolderInstrumentInputsLeftSide>

      <HolderInstrumentInputRightSide>
        <div style={{ width: '90%' }}>
          {Toggles.ToggleStatus({
            check: basicInfos.status,
            setCheck: () => {},
            key: 'status',
            updateValue: setBasicInfos,
            text: 'Status'
          })}
        </div>
        <div style={{ width: '90%' }}>
          {t('installationDate')}:
          <DatePickerComponent
            dateText={t('installationDate')}
            startDate={installationDate}
            setStartDate={setInstallDate}
            showTimeSelect={false}
          />
        </div>
        <div style={{ width: '90%' }}>
          {t('activactionDate')}:
          <DatePickerComponent
            dateText={t('activactionDate')}
            startDate={activationDate}
            setStartDate={setActivationDate}
            showTimeSelect={false}
          />
        </div>
        {InstrumentSelects.InstrumentType({
          width: 93.7,
          placeholder: t('ReadingMode'),
          options: InstrumentsModel().readingMode,
          setSelected: setReadingModeSelected,
          selected: readingModeSelected,
          key: 'readingType',
          updateValue: setBasicInfos,
          error: !basicInfos.readingType && basicInfosError
        })}
      </HolderInstrumentInputRightSide>
    </RegisterInstrumentInputs>
  );
};
