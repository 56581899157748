import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDelete } from 'react-icons/ai';
import { MdFileDownload } from 'react-icons/md';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import { DataType } from './GISTMListFilesInterface';
import {
  CommentContainer,
  DivComment,
  DivContainerListFiles,
  DivFilesRightSideContainer,
  DivListFiles
} from './GISTMListFilesStyle';
import { downloadGISTMFile } from '../../../../data/services/GISTMService';
import Comment from '../Comment/Comment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import { useParams, useSearchParams } from 'react-router-dom';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import { useMutation, useQuery } from '@apollo/client';
import {
  PageInfoDocument,
  PageInfoQuery,
  PageInfoQueryVariables
} from '../../../../data/graphql/query/generated/findGistmFilesPage.query';
import {
  FindGistmCommentsDocument,
  FindGistmCommentsQuery,
  FindGistmCommentsQueryVariables
} from '../../../../data/graphql/query/generated/findGistmComments.query';
import {
  DeleteGistmFileDocument,
  DeleteGistmFileMutation,
  DeleteGistmFileMutationVariables
} from '../../../../data/graphql/generated/deleteGistmFile.mutation';
import {
  SaveGistmCommentsDocument,
  SaveGistmCommentsMutation,
  SaveGistmCommentsMutationVariables
} from '../../../../data/graphql/generated/saveGistmComments.mutation';

const commentSchema = z.object({
  comment: z.string().min(1, { message: 'Preencha o campo de comentário!' })
});

type schemaType = z.infer<typeof commentSchema>;

const GISTMListFiles = () => {
  const { t: translate } = useTranslation();
  const { structureId, gistmId } = useParams();
  const [searchParams] = useSearchParams();

  const [idDeleteGistm, setIdDeleteGistm] = useState<string | null>();

  const { data: allGistmComments } = useQuery<
    FindGistmCommentsQuery,
    FindGistmCommentsQueryVariables
  >(FindGistmCommentsDocument, {
    variables: {
      gistmId: gistmId!,
      structureId: structureId!
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  const { data: allGistmFiles } = useQuery<
    PageInfoQuery,
    PageInfoQueryVariables
  >(PageInfoDocument, {
    variables: {
      gistmId: gistmId!,
      structureId: structureId!,
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      }
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  const [deleteGistmFile] = useMutation<
    DeleteGistmFileMutation,
    DeleteGistmFileMutationVariables
  >(DeleteGistmFileDocument);

  const [saveGistmComments] = useMutation<
    SaveGistmCommentsMutation,
    SaveGistmCommentsMutationVariables
  >(SaveGistmCommentsDocument);

  const totalPages = allGistmFiles?.findGistmFilesPag.pageInfo.totalPages;

  const columns = [
    { key: 'File', label: `${translate('File')}` },
    { key: 'date', label: `${translate('date')}` }
  ];

  const dataTable = allGistmFiles?.findGistmFilesPag.nodes.map((x) => {
    return {
      id: x.id,
      File: x.fileName,
      date: new Date(x.createdAt).toLocaleDateString() || '',
      url: x.file
    };
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors }
  } = useForm<schemaType>({
    resolver: zodResolver(commentSchema)
  });
  const HandleSubmitComment = async (data: schemaType) => {
    await saveGistmComments({
      variables: {
        structureId: structureId!,
        comments: {
          gistmId: gistmId!,
          comment: data.comment
        }
      },
      refetchQueries: [FindGistmCommentsDocument],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toastfySuccess(translate('saving'));
        reset();
      },
      onError: () => {
        toastfyError(translate('error'));
      }
    });
  };

  const handleDeleteClick = async (data: DataType) => {
    setIdDeleteGistm(data.id);
  };

  const actions: AvailableContextActionsProps<DataType> = [
    {
      name: `${translate('Download')}`,
      icon: <MdFileDownload />,
      onClick: downloadGISTMFile
    },
    {
      name: `${translate('Delete')}`,
      icon: <AiFillDelete />,
      onClick: handleDeleteClick
    }
  ];

  const handleConfirmDelete = async (id: string) => {
    await deleteGistmFile({
      variables: {
        fileId: id
      },
      errorPolicy: 'all',
      refetchQueries: [PageInfoDocument],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: () => {
        toastfyError(translate('errorDelete'));
      }
    });
    setIdDeleteGistm(null);
  };

  return (
    <DivContainerListFiles>
      {dataTable?.length === 0 ? (
        <Text type="span"> {translate('ThereAreNoRegisteredFiles')}</Text>
      ) : (
        <DivListFiles>
          <DivFilesRightSideContainer>
            <TableComponent
              columns={columns}
              tableData={dataTable || []}
              actions={actions}
              showInUrl={true}
              paginator={true}
              totalPages={totalPages}
            />
          </DivFilesRightSideContainer>
          <DivFilesRightSideContainer>
            <DivComment>
              <InputTextarea
                name="comment"
                label="Comentário"
                width="100%"
                register={register}
                error={!!errors.comment}
                errorMessage={errors.comment?.message}
              />
              <Button
                variant={'primary'}
                text="Send"
                size="small"
                onClick={handleSubmit(HandleSubmitComment)}
              />
            </DivComment>
            <CommentContainer>
              {allGistmComments?.findGistmComments
                .map((comment) => (
                  <Comment
                    key={comment.id}
                    user={comment.userName}
                    acronym={comment.userName}
                    date={
                      new Date(comment?.createdAt).toLocaleDateString() || ''
                    }
                    comment={comment.comment}
                  />
                ))
                .reverse()}
            </CommentContainer>
          </DivFilesRightSideContainer>
        </DivListFiles>
      )}
      {idDeleteGistm && (
        <ModalConfirm
          onClose={() => setIdDeleteGistm(null)}
          onConfirm={() => handleConfirmDelete(idDeleteGistm)}
          title={translate('Delete')}
          text={translate('ConfirmDeleteFile')}
        />
      )}
    </DivContainerListFiles>
  );
};

export default GISTMListFiles;
