import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { IoIosClose } from 'react-icons/io';
import { PiAsteriskBold } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../../Components/Buttons/ButtonsStyle';
import { InputFile } from '../../../../Components/Forms/FormInspectionsStyle';
import { CloseIconStyle } from '../../../../Components/Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle,
  RegisterNumericStyle
} from '../../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { StyleInstrumentSelect } from '../../../../Components/Selects/InstrumentSelect';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import {
  AdminInput,
  ButtonAreaAdmin,
  IconRequired,
  InputArea,
  RequiredInput
} from '../../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../../Styles/Styles';
import {
  CreateSectionInput,
  SectionTypeEnum
} from '../../../../data/graphql/base-schema';
import { useCreateSection } from '../../../../data/hooks/admin-structure-sections/use-create-section';
import { useEditSection } from '../../../../data/hooks/admin-structure-sections/use-edit-section';
import { useListSection } from '../../../../data/hooks/admin-structure-sections/use-list-section';
import { useListStructures } from '../../../../data/hooks/use-list-structures.query';
import { postFile } from './Sections.rest';
import {
  Mandatory,
  __initialMandatory,
  __initialSectionsData
} from './SectionsInterface';
import Toggle from 'react-toggle';
import Text from '../../../../Components/V2/Atoms/Text/Text';
// TODO: Implementar envio de imagem
// import InputFileWithList from '../../../../Components/V2/Organisms/InputFileWithList/InputFileWithList';
import { FileType } from '../../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';

export function SectionsModal({
  setShowModal,
  edit,
  setEdit,
  sectionsData,
  setSectionsData
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  sectionsData: CreateSectionInput;
  setSectionsData: Dispatch<SetStateAction<CreateSectionInput>>;
}) {
  const { t } = useTranslation();
  const { data: listStructures } = useListStructures();
  const { data: listSection } = useListSection();
  const { createSection } = useCreateSection();
  const { editEditSection } = useEditSection();
  const [structures, setStructures] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [image, setImage] = useState<FileType[]>([]);
  const token = localStorage.user ? JSON.parse(localStorage.user).token : null;
  const [mandatorys, setMandatorys] = useState<Mandatory>(__initialMandatory);
  const [isUTM, setIsUTM] = useState<boolean>(edit.data.isUTM || false);

  useEffect(() => {
    if (edit.status && edit.data) {
      setShowModal(true);
      setSectionsData((prev) => ({
        ...prev,
        structureId:
          listStructures?.listStructures.find(
            (role) => role.name === edit.data.Structure
          )?.structureId ?? '',
        finalCoordinateE: edit.data.CoordEFinal,
        finalCoordinateN: edit.data.CoordNFinal,
        initialCoordinateE: edit.data.CoordEInitial,
        initialCoordinateN: edit.data.CoordNInitial,
        structureName: edit.data.Structure,
        name: edit.data.Section
      }));
    }

    if (listStructures) {
      const structures = listStructures.listStructures
        .filter((e) => e.status === true)
        .map((structure: any) => ({
          id: structure.structureId,
          label: structure.name,
          value: structure.structureId
        }));
      setStructures(structures);
    }
  }, [listStructures]);

  const mandatoryInputs = () => {
    const checkEmptyFields = (data: CreateSectionInput): string[] => {
      const emptyFields: string[] = [];

      if (data.finalCoordinateE <= 0) emptyFields.push('finalCoordinateE');
      if (data.finalCoordinateN <= 0) emptyFields.push('finalCoordinateN');
      if (!data.name) emptyFields.push('name');
      if (data.initialCoordinateE <= 0) emptyFields.push('initialCoordinateE');
      if (data.initialCoordinateN <= 0) emptyFields.push('initialCoordinateN');
      if (!data.type) emptyFields.push('type');
      if (!data.structureId) emptyFields.push('structureId');
      // if (image.length === 0) emptyFields.push('image'); // TODO: Implementar envio de imagem

      return emptyFields;
    };
    const emptyFields = checkEmptyFields(sectionsData);

    for (const item of emptyFields) {
      setMandatorys((prev) => ({
        ...prev,
        [item]: false
      }));
    }
  };

  const sendModule = async () => {
    if (
      sectionsData.name &&
      sectionsData.structureId &&
      sectionsData.finalCoordinateE &&
      sectionsData.finalCoordinateN &&
      sectionsData.initialCoordinateE &&
      sectionsData.initialCoordinateN
      // image.length > 0 // TODO: Implementar envio de imagem
    ) {
      const formData = new FormData();
      formData.append('image', image[0]?.file ? image[0]?.file : '');

      await createSection({
        variables: {
          data: {
            name: sectionsData.name,
            structureId: sectionsData.structureId,
            type: SectionTypeEnum.Cross,
            finalCoordinateE: sectionsData.finalCoordinateE,
            finalCoordinateN: sectionsData.finalCoordinateN,
            initialCoordinateE: sectionsData.initialCoordinateE,
            initialCoordinateN: sectionsData.initialCoordinateN,
            isUTM: isUTM
          }
        }
      }).then(async (responseData) => {
        if (image[0]?.file && responseData.data?.createSection) {
          await postFile(token, responseData.data?.createSection, formData);
          toastifyLoading(`${t('registering')} ${t('Section')}...`);
          if (responseData.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(`${t('Section')} ${t('registeredSuccessfully')}!`);
            setShowModal(false);
            setSectionsData(__initialSectionsData);
            setEdit({ status: false, data: {} });
          } else if (responseData.errors) {
            toastfyDimiss('toastLoading');
            toastfyError(
              responseData.errors[0].message ||
                t(ToastifyModel().toastifyMessage.error)
            );
          }
        } else {
          toastifyLoading(`${t('registering')} ${t('Section')}...`);
          if (responseData.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(`${t('Section')} ${t('registeredSuccessfully')}!`);
            setShowModal(false);
            setSectionsData(__initialSectionsData);
            setEdit({ status: false, data: {} });
          } else if (responseData.errors) {
            toastfyDimiss('toastLoading');
            toastfyError(
              responseData.errors[0].message ||
                t(ToastifyModel().toastifyMessage.error)
            );
          }
        }
      });
    } else {
      mandatoryInputs();
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSectionsData(__initialSectionsData);
    setEdit({ status: false, data: {} });
  };

  const editModule = async () => {
    const section = listSection?.listSection.find(
      (e) =>
        e.finalCoordinateE === edit.data.CoordEFinal &&
        e.structureName === edit.data.Structure &&
        e.name === edit.data.Section
    );

    if (section?.id) {
      await editEditSection({
        variables: {
          data: {
            id: section.id,
            name: sectionsData.name,
            structureId: sectionsData.structureId,
            type: SectionTypeEnum.Cross,
            finalCoordinateE: sectionsData.finalCoordinateE,
            finalCoordinateN: sectionsData.finalCoordinateN,
            initialCoordinateE: sectionsData.initialCoordinateE,
            initialCoordinateN: sectionsData.initialCoordinateN,
            isUTM: isUTM
          }
        }
      }).then(async (responseData) => {
        const formData = new FormData();
        formData.append('image', image[0]?.file ? image[0]?.file : '');

        if (image[0]?.file && responseData.data) {
          await postFile(token, section.id, formData);
          toastifyLoading(`${t('registering')} ${t('Section')}...`);
          if (responseData.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(`${t('Section')} ${t('editedSuccessfully')}!`);
            setShowModal(false);
            setSectionsData(__initialSectionsData);
            setEdit({ status: false, data: {} });
          } else if (responseData.errors) {
            toastfyDimiss('toastLoading');
            toastfyError(
              responseData.errors[0].message ||
                t(ToastifyModel().toastifyMessage.error)
            );
          }
        } else {
          toastifyLoading(`${t('registering')} ${t('Section')}...`);
          if (responseData.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(`${t('Section')} ${t('editedSuccessfully')}!`);
            setShowModal(false);
            setSectionsData(__initialSectionsData);
            setEdit({ status: false, data: {} });
          } else if (responseData.errors) {
            toastfyDimiss('toastLoading');
            toastfyError(
              responseData.errors[0].message ||
                t(ToastifyModel().toastifyMessage.error)
            );
          }
        }
      });
    } else {
      mandatoryInputs();
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '0',
          maxHeight: '100%'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${edit.data.id ? t('Edit') : t('Register')} ${t('Section')}`}</RegisterInstrumentTitle>
          <IoIosClose
            size={35}
            onClick={() => handleClose()}
            style={CloseIconStyle}
          />
        </RegisterInstrumentHeader>
        <InputArea>
          <RequiredInput style={{ position: 'relative', width: '50%' }}>
            {sectionsData.structureName ? (
              <AdminInput
                value={sectionsData.structureName}
                disabled
                style={{ width: '100%', margin: '15px 0' }}
                placeholder={t('name')}
              />
            ) : (
              <Select
                styles={{
                  ...StyleInstrumentSelect,
                  control: (provided) => ({
                    ...provided,
                    border: 'none',
                    borderBottom: mandatorys.structureId
                      ? `1px solid ${Color.Brown1}`
                      : '1px solid red',
                    borderRadius: 'none',
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    padding: 'none',
                    fontSize: '11pt',
                    cursor: 'pointer',
                    marginBottom: '13px',
                    userSelect: 'none',
                    background: 'none',
                    ':hover': {
                      borderBottom: mandatorys.structureId
                        ? `1px solid ${Color.Brown1}`
                        : '1px solid red'
                    }
                  })
                }}
                isSearchable={false}
                placeholder={t('Structure')}
                hideSelectedOptions
                options={structures}
                onChange={(e: any) => {
                  setSectionsData((prev) => ({
                    ...prev,
                    structureId: e.id
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                value={structures.find(
                  (structure: any) => structure.id === sectionsData.structureId
                )}
              />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              value={sectionsData.name}
              onChange={(e) => {
                setSectionsData((prevData) => ({
                  ...prevData,
                  name: e.target.value
                }));
                toastfyDimiss('toastError');
              }}
              style={{ borderColor: mandatorys.name ? '' : 'red' }}
              placeholder={t('name')}
            />
            {sectionsData.name.length > 0 ? (
              <BsCheckLg style={IconRequired} />
            ) : (
              <PiAsteriskBold style={IconRequired} />
            )}
          </RequiredInput>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              padding: '15px',
              gap: '10px'
            }}
          >
            <Text type="label">{t('IsUTM')}:</Text>
            <Toggle
              className="custom-toggle"
              checked={isUTM}
              onChange={(e) => setIsUTM(e.target.checked)}
            />
          </div>
          <span
            style={{
              display: 'flex',
              color: Color.Brown1,
              width: '100%',
              justifyContent: 'center',
              padding: '15px 0',
              fontWeight: '800'
            }}
          >
            {t('initialPoint')}
          </span>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e) => {
                setSectionsData((prevData) => ({
                  ...prevData,
                  initialCoordinateE: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor: mandatorys.initialCoordinateE ? '' : 'red'
              }}
              placeholder={`${t('coordinateE')}`}
              value={
                sectionsData.initialCoordinateE === 0
                  ? ''
                  : sectionsData.initialCoordinateE
              }
            />
            {sectionsData.initialCoordinateE === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e) => {
                setSectionsData((prevData) => ({
                  ...prevData,
                  initialCoordinateN: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor: mandatorys.initialCoordinateN ? '' : 'red'
              }}
              placeholder={`${t('coordinateN')}`}
              value={
                sectionsData.initialCoordinateN === 0
                  ? ''
                  : sectionsData.initialCoordinateN
              }
            />
            {sectionsData.initialCoordinateN === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <span
            style={{
              display: 'flex',
              color: Color.Brown1,
              width: '100%',
              justifyContent: 'center',
              padding: '15px 0',
              fontWeight: '800'
            }}
          >
            {t('finalPoint')}
          </span>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e) => {
                setSectionsData((prevData) => ({
                  ...prevData,
                  finalCoordinateE: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor: mandatorys.finalCoordinateE ? '' : 'red'
              }}
              placeholder={`${t('coordinateE')}`}
              value={
                sectionsData.finalCoordinateE === 0
                  ? ''
                  : sectionsData.finalCoordinateE
              }
            />
            {sectionsData.finalCoordinateE === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e) => {
                setSectionsData((prevData) => ({
                  ...prevData,
                  finalCoordinateN: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor: mandatorys.finalCoordinateN ? '' : 'red'
              }}
              placeholder={`${t('coordinateN')}`}
              value={
                sectionsData.finalCoordinateN === 0
                  ? ''
                  : sectionsData.finalCoordinateN
              }
            />
            {sectionsData.finalCoordinateN === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          {/* TODO: Implementar envio de imagem */}
          {/* <InputFileWithList
            error={!mandatorys.image}
            accept=".pdf, .png, .jpg, .jpeg"
            label="Image"
            multiple={false}
            files={image}
            errorMessage={t('requiredField')}
            onChange={(event) => {
              setImage([
                {
                  id: null,
                  name: event.target.files![0].name,
                  url: URL.createObjectURL(event.target.files![0]),
                  file: event.target.files![0]
                }
              ]);
            }}
            name="image"
            onDelete={() =>
              image[0]?.id
                ? toastfyError(t('notPossibleDeleteFileTryUpdate'))
                : setImage([])
            }
          /> */}
        </InputArea>
        <ButtonAreaAdmin style={{ justifyContent: 'flex-end' }}>
          <CancelButtonStyled onClick={() => handleClose()}>
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          {!edit.status && (
            <AdvanceButtonStyled onClick={() => sendModule()}>
              {' '}
              {t('Save').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
          {edit.status && (
            <AdvanceButtonStyled onClick={() => editModule()}>
              {' '}
              {t('Edit').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
