import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  FindAllAssociatedActionPlansDocument,
  FindAllAssociatedActionPlansQuery,
  FindAllAssociatedActionPlansQueryVariables
} from '../../../../data/graphql/query/generated/findAllAssociatedActionPlans.query';
import { API_URL } from '../../../../utils/const';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';
import Icon from '../../Atoms/Icon/Icon';
import Image from '../../Atoms/Image/Image';
import Text from '../../Atoms/Text/Text';
import FileViewer from '../../Molecules/FileViewer/FileViewer';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputText from '../../Molecules/InputText/InputText';
import SeeMoreTemplate from '../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import {
  DivContainerDescription,
  DivContainerFilterHistory,
  DivContainerText,
  DivContentDescription,
  DivContentDescriptionTitle,
  DivImages
} from './ActionPlanHistoryPage.styles';

const ActionPlanHistoryPage = () => {
  const { t: translate } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null);
  const [files, setFiles] = useState<FileType[]>([]);
  const { structureId, actionPlanId } = useParams();
  const navigate = useNavigate();
  const {
    data: actionPlanAssociatedData,
    loading: loadingActionPlanAssociated
  } = useQuery<
    FindAllAssociatedActionPlansQuery,
    FindAllAssociatedActionPlansQueryVariables
  >(FindAllAssociatedActionPlansDocument, {
    variables: {
      actionPlanId: actionPlanId!
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const filterHistory = (code: string) => {
    setSearchParams({ code: code });
  };

  const filteredData =
    actionPlanAssociatedData?.findAllAssociatedActionPlans.filter(
      (actionPlan) => actionPlan.code.includes(searchParams.get('code') || '')
    );

  const handleNavigateBack = () => {
    navigate(`/${structureId}/actionplan/${actionPlanId}`);
  };

  return (
    <>
      <SeeMoreTemplate
        icon={
          <Icon Icon={IoIosArrowBack} onClick={() => handleNavigateBack()} />
        }
        title={translate('history')}
        header={
          <DivContainerFilterHistory>
            <InputText
              label={translate('code')}
              name="name"
              type="text"
              width="300px"
              value={searchParams.get('code') || ''}
              onChange={(event) => filterHistory(event.target.value)}
            />
          </DivContainerFilterHistory>
        }
        content={filteredData?.map((plan, index) => {
          const urlsPicturesData = plan?.images.map((url) => ({
            url: `${API_URL}/${url}`,
            name: url,
            id: url,
            file: undefined
          }));
          return (
            <DivContainerDescription key={index}>
              <DivContentDescriptionTitle>
                <Text type="h5" size="medium" weight="bold" color="brown">
                  {plan.code}
                </Text>
                <Text type="span" size="small">
                  {new Date(plan.createdAt).toLocaleDateString('pt-br')}
                </Text>
              </DivContentDescriptionTitle>
              <DivContentDescription>
                <DivImages>
                  {urlsPicturesData?.map((picture, index) => (
                    <Image
                      key={index}
                      alt={picture.url}
                      src={picture.url}
                      width="50%"
                      onClick={() => {
                        setSelectedFile(picture);
                        setFiles(urlsPicturesData);
                      }}
                    />
                  ))}
                </DivImages>
                <DivContainerText>{plan.description}</DivContainerText>
              </DivContentDescription>
            </DivContainerDescription>
          );
        })}
        loading={loadingActionPlanAssociated}
      />
      {selectedFile && (
        <FileViewer
          files={files}
          setSelectedFile={setSelectedFile}
          onClose={() => setSelectedFile(null)}
          selectedFile={selectedFile}
        />
      )}
    </>
  );
};

export default ActionPlanHistoryPage;
