import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { z, ZodIssueCode } from 'zod';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Text from '../../Atoms/Text/Text';
import InputText from '../../Molecules/InputText/InputText';
import { MdEdit } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import Button from '../../Atoms/Button/Button';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  WaterLevelAlertLevelsType
} from '../../../../data/graphql/base-schema';
import Enums from '../../../../utils/enumns';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import {
  ListInstrumentByIdQuery,
  ListInstrumentByIdQueryVariables,
  ListInstrumentByIdDocument
} from '../../../../data/graphql/query/generated/listInstrumentsById';
import { useState } from 'react';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AlertLevelFormPv from '../../Organisms/AlertLevelFormPV/AlertLevelFormPv';
import { listAlertlevelDataProps } from './EditWaterLevel.interfaces';
import {
  DeleteInstrumentAlertLevelMutation,
  DeleteInstrumentAlertLevelMutationVariables,
  DeleteInstrumentAlertLevelDocument
} from '../../../../data/graphql/generated/deleteInstrumentAlertLevel.mutation';
import { ListWaterLevelByStructureDocument } from '../../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import {
  EditWaterLevelMutation,
  EditWaterLevelMutationVariables,
  EditWaterLevelDocument
} from '../../../../data/graphql/generated/editWaterLevel.mutation';
import {
  ListAlertsLevelByWaterLevelPagQuery,
  ListAlertsLevelByWaterLevelPagQueryVariables,
  ListAlertsLevelByWaterLevelPagDocument
} from '../../../../data/graphql/query/generated/listAlertLevelsByWaterLevelPag';
import {
  BorderContainer,
  BorderContainerSpecificInfos,
  Footer,
  HolderAlertLevel,
  HolderCommonInfos,
  HolderContainerTitle,
  HolderSpecificsInfo,
  MainContainer
} from './EditWaterLevel.styles';
import {
  DivInputs,
  DivButton
} from '../EditPiezometerAndInaPage/EditPiezometerAndInaPage.styles';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import { GroupText } from '../../Molecules/GroupText/GroupText';

const EditWaterLevel = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [alertLevelEdit, setAlertLevelEdit] =
    useState<listAlertlevelDataProps | null>(null);
  const [pageAlerts, setPageAlerts] = useState<number>(1);
  const [deleteAlertLevel, setDeleteAlertLevel] = useState<string | null>(null);

  const { t: translate } = useTranslation();
  const { instrumentId, structureId } = useParams();
  const { OperationalStatus, TypeOfReading, TypeOfInstallation } = Enums();
  const canEdit = location.pathname.includes('edit');

  const [getInstumentData] = useLazyQuery<
    ListInstrumentByIdQuery,
    ListInstrumentByIdQueryVariables
  >(ListInstrumentByIdDocument);

  const [deleteInstrumentAlertLevel] = useMutation<
    DeleteInstrumentAlertLevelMutation,
    DeleteInstrumentAlertLevelMutationVariables
  >(DeleteInstrumentAlertLevelDocument);

  const { data: waterLevelsAlertLevels } = useQuery<
    ListAlertsLevelByWaterLevelPagQuery,
    ListAlertsLevelByWaterLevelPagQueryVariables
  >(ListAlertsLevelByWaterLevelPagDocument, {
    variables: {
      instrumentId: instrumentId!,
      pageInfo: {
        page: pageAlerts,
        limit: 3
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const [editWaterLevel] = useMutation<
    EditWaterLevelMutation,
    EditWaterLevelMutationVariables
  >(EditWaterLevelDocument);

  const basicInfoInstrumentSchema = z
    .object({
      name: z.string().min(1, translate('fieldIsRequired')),
      coordinateE: z.string(),
      coordinateN: z.string(),
      installationDate: z.date().nullable(),
      activationDate: z.date().nullable(),
      operationalStatus: z
        .object({
          value: z.string(),
          label: z.string()
        })
        .nullable()
        .refine((val) => val !== null, {
          message: translate('fieldIsRequired')
        }),
      deactivationDate: z.date().nullable().optional(),
      installLocation: z.object({
        value: z.string(),
        label: z.string()
      }),
      readingType: z
        .object({
          value: z.string(),
          label: z.string()
        })
        .nullable()
        .refine((val) => val !== null, {
          message: translate('fieldIsRequired')
        }),
      isUTM: z.boolean()
    })
    .superRefine((data, ctx) => {
      const {
        installationDate,
        activationDate,
        coordinateE,
        coordinateN,
        isUTM,
        operationalStatus,
        deactivationDate
      } = data;

      if (!installationDate && activationDate) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['installationDate'],
          message: translate('fieldIsRequired')
        });
      }

      if (
        activationDate &&
        installationDate &&
        activationDate < installationDate
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['activationDate'],
          message: translate('dateActivationIsLessThanInstallationDate')
        });
      }

      if (
        !isUTM &&
        (parseInt(coordinateE) > 90 || parseInt(coordinateE) < -90)
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateE'],
          message: translate('ValidationCoordinateE')
        });
      }

      if (
        !isUTM &&
        (parseInt(coordinateN) > 180 || parseInt(coordinateN) < -180)
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateN'],
          message: translate('ValidationCoordinateN')
        });
      }

      if (isUTM && parseInt(coordinateE) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateE'],
          message: translate('coordinateUtmCannottNegative')
        });
      }

      if (isUTM && parseInt(coordinateN) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateN'],
          message: translate('coordinateUtmCannottNegative')
        });
      }

      if (operationalStatus.value === 'Inactive' && !deactivationDate) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['deactivationDate'],
          message: translate('fieldIsRequired')
        });
      }

      if (
        operationalStatus.value === 'Inactive' &&
        deactivationDate &&
        activationDate &&
        deactivationDate < activationDate
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['deactivationDate'],
          message: translate('dateDeactivationNeedToBeGreaterThanActivation')
        });
      }
    });

  type instrumentCommonInfosType = z.infer<typeof basicInfoInstrumentSchema>;

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch
  } = useForm<instrumentCommonInfosType>({
    resolver: zodResolver(basicInfoInstrumentSchema),
    defaultValues: async () => {
      try {
        const { data: instrumentData } = await getInstumentData({
          variables: { instrumentId: instrumentId! },
          onError: ({ graphQLErrors }) => {
            const errorMessage = ErrorsTreatments(
              graphQLErrors[0].message,
              translate
            );
            toastfyError(errorMessage);
          }
        });
        if (instrumentData?.listInstrumentById) {
          const instrument = instrumentData.listInstrumentById;

          return {
            name: instrument.name,
            coordinateE: instrument.coordinateE.toString() || '',
            coordinateN: instrument.coordinateN.toString() || '',
            isUTM: instrument.isUTM,
            activationDate: instrument.activationDate
              ? new Date(instrument.activationDate)
              : new Date(),
            installationDate: instrument.installationDate
              ? new Date(instrument.installationDate)
              : new Date(),
            operationalStatus: {
              value: instrument.operationalStatus || '',
              label: instrument.operationalStatus || ''
            },
            deactivationDate: instrument.deactivationDate
              ? new Date(instrument.deactivationDate)
              : new Date(),
            installLocation: {
              value: instrument.installLocation || '',
              label: instrument.installLocation || ''
            },
            readingType: {
              value: instrument.readingType || '',
              label: instrument.readingType || ''
            }
          };
        } else {
          return {
            name: '',
            coordinateE: '',
            coordinateN: '',
            isUTM: false,
            activationDate: null,
            installationDate: null,
            operationalStatus: {
              value: '',
              label: ''
            },
            deactivationDate: null,
            installLocation: {
              value: '',
              label: ''
            },
            readingType: {
              value: '',
              label: ''
            }
          };
        }
      } catch (error) {
        return {
          name: '',
          coordinateE: '',
          coordinateN: '',
          isUTM: false,
          activationDate: null,
          installationDate: null,
          operationalStatus: {
            value: '',
            label: ''
          },
          deactivationDate: null,
          installLocation: {
            value: '',
            label: ''
          },
          readingType: {
            value: '',
            label: ''
          }
        };
      }
    }
  });

  const listWaterLevelsAlertLevels =
    waterLevelsAlertLevels?.listAlertsLevelByWaterLevelPag.nodes.map(
      (waterLevelsAlertLevels: WaterLevelAlertLevelsType) => ({
        name: waterLevelsAlertLevels.name,
        value: waterLevelsAlertLevels.value,
        date: new Date(waterLevelsAlertLevels?.date ?? '').toLocaleDateString(),
        id: waterLevelsAlertLevels.id
      })
    );

  const totalPagesAlertLevels =
    waterLevelsAlertLevels?.listAlertsLevelByWaterLevelPag.pageInfo.totalPages;

  const handleDeleteAlertLevel = async (id: string) => {
    await deleteInstrumentAlertLevel({
      variables: {
        structureId: structureId!,
        data: {
          instrumentId: instrumentId!,
          alertId: id
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ListInstrumentByIdDocument,
          variables: {
            instrumentId: instrumentId!
          }
        },
        ListAlertsLevelByWaterLevelPagDocument
      ],
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
        setDeleteAlertLevel(null);
      },
      onCompleted: () => {
        toastfySuccess(translate('deletedSuccessfully'));
        setDeleteAlertLevel(null);
      }
    });
  };

  const columnsTable = [
    {
      key: 'name',
      label: `${translate('name')}`
    },
    {
      key: 'value',
      label: `${translate('value')}`
    }
  ];

  const actionsTable: AvailableContextActionsProps<listAlertlevelDataProps>[] =
    [
      {
        name: `${translate('Edit')}`,
        icon: <MdEdit />,
        onClick: (alertlevel: listAlertlevelDataProps) =>
          handleEditAlertlevel(alertlevel),
        canShow: 'canEdit'
      },

      {
        name: `${translate('Delete')}`,
        icon: <TiDelete />,
        onClick: (alertlevel: listAlertlevelDataProps) => {
          setDeleteAlertLevel(alertlevel.id);
        },
        canShow: 'canCancel'
      }
    ];

  const handleEditWaterLevel = (dataFromForm: instrumentCommonInfosType) => {
    const waterLevel = {
      instrumentId: instrumentId!,
      name: dataFromForm.name,
      coordinateE: Number(dataFromForm.coordinateE),
      coordinateN: Number(dataFromForm.coordinateN),
      isUTM: dataFromForm.isUTM,
      operationalStatus: dataFromForm.operationalStatus
        .value as InstrumentStatusEnum,
      readingType: dataFromForm.readingType.value as InstrumentReadingTypeEnum,
      activationDate: dataFromForm.activationDate,
      deactivationDate:
        dataFromForm.operationalStatus.value === 'Active'
          ? null
          : dataFromForm.deactivationDate,
      installationDate: dataFromForm.installationDate,
      installLocation: dataFromForm.installLocation.value
    };
    editWaterLevel({
      variables: {
        structureInfo: {
          structureId: structureId || '',
          associatedStructureId: ''
        },
        data: {
          generalInfo: waterLevel
        }
      },
      refetchQueries: [
        {
          query: ListWaterLevelByStructureDocument,
          variables: {
            structureInfo: {
              structureId: structureId!,
              associatedStructureId: null
            }
          }
        },
        {
          query: ListInstrumentByIdDocument,
          variables: {
            instrumentId: instrumentId!
          }
        }
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toastfySuccess(translate('updatedSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  const handleEditAlertlevel = (alertLevelRole: listAlertlevelDataProps) => {
    const alertLevel =
      waterLevelsAlertLevels?.listAlertsLevelByWaterLevelPag.nodes.find(
        (item) => item.id === alertLevelRole.id
      );
    if (!alertLevel) return;
    const newAlertLevel = {
      id: alertLevel.id,
      name: alertLevel.name,
      value: alertLevel.value.toString()
    };
    setAlertLevelEdit(newAlertLevel);
    setShowModal(true);
  };

  return (
    <>
      <MainContainer>
        <HolderCommonInfos>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('basicInfos')}
            </Text>
          </HolderContainerTitle>
          {canEdit ? (
            <BorderContainer>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('name')}
                  name={'name'}
                  type={'text'}
                  error={!!errors.name}
                  errorMessage={errors?.name?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('coordinateE')}
                  name={'coordinateE'}
                  type={'number'}
                  error={!!errors.coordinateE}
                  errorMessage={errors?.coordinateE?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('coordinateN')}
                  name={'coordinateN'}
                  type={'number'}
                  error={!!errors.coordinateN}
                  errorMessage={errors?.coordinateN?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputCheckbox
                  register={register}
                  label={translate('coordinateUTM')}
                  name={'isUTM'}
                  value={watch('isUTM')}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('installationDate')}
                  name="installationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.installationDate}
                  errorMessage={errors?.installationDate?.message}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('activationDate')}
                  name="activationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.activationDate}
                  errorMessage={errors?.activationDate?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('operationalStatus')}
                  width="300px"
                  label={translate('operationalStatus')}
                  options={OperationalStatus}
                  name="operationalStatus"
                  error={!!errors.operationalStatus}
                  errorMessage={errors?.operationalStatus?.message}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('deactivationDate')}
                  name="deactivationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.deactivationDate}
                  errorMessage={errors?.deactivationDate?.message}
                  disabled={watch('operationalStatus')?.value === 'Active'}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('installLocation')}
                  width="300px"
                  label={translate('installLocation')}
                  options={TypeOfInstallation}
                  name="installLocation"
                  error={!!errors.installLocation}
                  errorMessage={errors?.installLocation?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('typeOfReading')}
                  width="300px"
                  label={translate('typeOfReading')}
                  options={TypeOfReading}
                  name="readingType"
                  error={!!errors.readingType}
                  errorMessage={errors?.readingType?.message}
                />
              </DivInputs>
              <DivButton>
                {canEdit && (
                  <Button
                    variant="primary"
                    onClick={handleSubmit(handleEditWaterLevel)}
                    text={translate('saveGeneralData')}
                  />
                )}
              </DivButton>
            </BorderContainer>
          ) : (
            <BorderContainer>
              <DivInputs>
                <GroupText title={translate('name')} value={watch('name')} />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateE')}
                  value={watch('coordinateE')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateN')}
                  value={watch('coordinateN')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateUTM')}
                  value={watch('isUTM') ? translate('Yes') : translate('No')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('installationDate')}
                  value={
                    watch('installationDate')
                      ? new Date(
                          watch('installationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('activationDate')}
                  value={
                    watch('activationDate')
                      ? new Date(
                          watch('activationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('operationalStatus')}
                  value={translate(
                    watch('operationalStatus')
                      ? watch('operationalStatus').label
                      : '-'
                  )}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('deactivationDate')}
                  value={
                    watch('deactivationDate')
                      ? new Date(
                          watch('deactivationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('installLocation')}
                  value={translate(
                    watch('installLocation')?.value
                      ? watch('installLocation').value
                      : '-'
                  )}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('typeOfReading')}
                  value={translate(
                    watch('readingType') ? watch('readingType').value : '-'
                  )}
                />
              </DivInputs>
            </BorderContainer>
          )}
        </HolderCommonInfos>
        <HolderSpecificsInfo>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('instrumentInformation')}
            </Text>
          </HolderContainerTitle>
          <BorderContainerSpecificInfos>
            <HolderAlertLevel>
              <Text color="brown" type="span" weight="bold">
                {translate('alertLevels')}
              </Text>
              <TableComponent
                tableData={listWaterLevelsAlertLevels || []}
                columns={columnsTable}
                actions={canEdit ? actionsTable : undefined}
                paginator
                pageState={{
                  currentPage: pageAlerts,
                  totalPages: totalPagesAlertLevels || 1,
                  nextPage: () => setPageAlerts(pageAlerts + 1),
                  previousPage: () => setPageAlerts(pageAlerts - 1)
                }}
                totalPages={totalPagesAlertLevels || 1}
              />
              <Footer>
                {canEdit && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setAlertLevelEdit(null);
                      setShowModal(true);
                    }}
                    text={translate('newAlertLevel')}
                  />
                )}
              </Footer>
            </HolderAlertLevel>
          </BorderContainerSpecificInfos>
        </HolderSpecificsInfo>
      </MainContainer>
      {showModal && (
        <ViewModal
          title={
            alertLevelEdit
              ? `${translate('editAlertlevel')}`
              : `${translate('newAlertLevel')}`
          }
          onClose={() => {
            setShowModal(false);
            setAlertLevelEdit(null);
          }}
          component={
            <AlertLevelFormPv
              alertLevel={alertLevelEdit}
              setShowModal={setShowModal}
            />
          }
          width={'auto'}
          height={'auto'}
        />
      )}

      {deleteAlertLevel && (
        <ModalConfirm
          text={translate('ConfirmDelete')}
          subtitle={translate('ActionCannotBeUndone')}
          title={translate('deleteAlertLevel')}
          onClose={() => setDeleteAlertLevel(null)}
          onConfirm={() => handleDeleteAlertLevel(deleteAlertLevel)}
        />
      )}
    </>
  );
};

export default EditWaterLevel;
