import styled from 'styled-components';

export const GraphWindowInstrument = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-height: 810px) {
    height: 80%;
  }
`;

export const DatePickerContainerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
`;
