import { Dispatch, SetStateAction } from 'react';
import PiezometerListPage from '../../Components/V2/Pages/Monitoring/PiezometerListPage';
import { useParams } from 'react-router-dom';
import PluviometerListPage from '../../Components/V2/Pages/Monitoring/PluviometerListPage';
import SurfaceMarkersListPage from '../../Components/V2/Pages/Monitoring/SurfaceMarkerListPage';
import WaterLevelListPage from '../../Components/V2/Pages/Monitoring/WaterLevelListPage';

export interface MapGoogleInfoWindowProps {
  instrument: {
    id: string;
    type: string;
    name: string;
  };
  setInfoWindowOpen: Dispatch<SetStateAction<boolean>>;
}

export type InstrumentType =
  | 'piezometer'
  | 'ina'
  | 'pluviometer'
  | 'waterlevel'
  | 'surfacemarker';

export const InstrumentDataListPage = () => {
  let { instrumentType } = useParams();

  instrumentType = instrumentType ? instrumentType : '';

  const instrumentListPageMap = {
    piezometer: PiezometerListPage,
    ina: PiezometerListPage,
    pluviometer: PluviometerListPage,
    waterlevel: WaterLevelListPage,
    surfacemarker: SurfaceMarkersListPage
  };

  const InstrumentInfoWindow =
    instrumentListPageMap[
      instrumentType?.toLocaleLowerCase() as InstrumentType
    ];

  return <InstrumentInfoWindow />;
};
