import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const Container = styled.div`
  background-color: ${Color.White};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  padding: 0 20px;
  box-sizing: border-box;
`;

export const DivHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DivContent = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const DivContentMenu = styled.div`
  width: 20%;
  height: 100%;
`;

export const DivContentTable = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;
