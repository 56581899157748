import React from 'react';
import {
  Container,
  DivContent,
  DivHeader,
  DivContentMenu,
  DivContentTable
} from './InstrumentTemplate.styles';
import { InstrumentTemplateProps } from './InstrumentTemplate.interface';
import Skeleton from 'react-loading-skeleton';

export function InstrumentTemplate({
  title,
  button,
  table,
  menu,
  loading
}: InstrumentTemplateProps) {
  return (
    <>
      {loading ? (
        <Container>
          <DivHeader>
            <Skeleton width="200px" height="48px" />
            <Skeleton width="200px" height="48px" />
          </DivHeader>
          <DivContent>
            <DivContentMenu>
              <Skeleton width="100%" height="800px" />
            </DivContentMenu>
            <DivContentTable>
              <Skeleton width="100%" height="800px" />
            </DivContentTable>
          </DivContent>
        </Container>
      ) : (
        <Container>
          <DivHeader>
            {title}
            {button}
          </DivHeader>
          <DivContent>
            <DivContentMenu>{menu}</DivContentMenu>
            <DivContentTable>{table}</DivContentTable>
          </DivContent>
        </Container>
      )}
    </>
  );
}

export default InstrumentTemplate;
