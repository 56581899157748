/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import 'react-toggle/style.css'; // Import the styles
import {
  InstalationLocaleInterface,
  InstrumentSelectInterface,
  ReadModeInterface,
  RegisterInstrumentModalInterface
} from '../../../@Types/Instruments/instruments';
import { useReducerHook } from '../../../Hooks/ReducerHook';
import ToastifyModel from '../../../Models/ToastifyModel';
import { InstrumentStatusEnum } from '../../../data/graphql/base-schema';
import { useCreatePiezometer } from '../../../data/hooks/piezometer/use-create-piezometer.mutation';
import { useAddPluviometer } from '../../../data/hooks/pluviometer/use-create-pluviometer.mutation';
import { useAddSurfaceMarker } from '../../../data/hooks/surface-marker/add-surface-marker-instrument.mutation';
import { useListIntrumentsType } from '../../../data/hooks/use-list-instruments-type-query';
import { useAddWaterLevel } from '../../../data/hooks/water-level/add-waterLevel-instrument.mutation';
import { toastfyDimiss, toastfyError, toastfySuccess } from '../../Toastify';
import ModalStep from '../../V2/Molecules/ModalStep/ModalStep';
import { RegisterBasicInfos } from './RegisterBasicInfos';
import { RegisterInstrumentInfos } from './RegisterInstrumentInfos';
import { ListInstrumentsByStructureDocument } from '../../../data/graphql/query/generated/listInstrumentsByStructure.query';
import { ListPiezometersByStructureDocument } from '../../../data/graphql/query/generated/listPiezometersByStructure.query';
import { ListPluviometersByStructureDocument } from '../../../data/graphql/query/generated/listPluviometersByStructure.query';
import { ListWaterLevelByStructureDocument } from '../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import { ListSurfaceMarkersByStructureDocument } from '../../../data/graphql/query/generated/listSurfaceMarkersByStructure.query';

export const RegisterInstrument = (props: RegisterInstrumentModalInterface) => {
  const { t } = useTranslation();
  const { structureId, instrumentType } = useParams();
  const { setShowModal } = props;
  const [readingModeSelected, setReadingModeSelected] =
    useState<ReadModeInterface>();
  const [subStructureSelected, setSubStructureSelected] = useState<
    InstrumentSelectInterface | undefined
  >();
  const [installationLocationSelected, setInstallationLocationSelected] =
    useState<InstalationLocaleInterface>();
  const [activactionDate, setActivactionDate] = useState<Date>(new Date());
  const [installationDate, setInstallationDate] = useState<Date>(new Date());
  const [basicInfosError, setBasicInfosError] = useState<boolean>(false);
  const [specificInfosError, setSpecificInfosError] = useState<boolean>(false);
  const [crossSection, setCrossSection] = useState<
    | {
        id: string | undefined;
        value: string | undefined;
        label: string | undefined;
      }
    | undefined
  >(undefined);
  const [longitudinalSection, setLongitudinalSection] = useState<
    | {
        id: string | undefined;
        value: string | undefined;
        label: string | undefined;
      }
    | undefined
  >(undefined);
  const { data: listInstruments } = useListIntrumentsType();
  const { addPluviometer } = useAddPluviometer();
  const { addWaterLevel } = useAddWaterLevel();
  const { addSurfaceMarker } = useAddSurfaceMarker();
  const { addPiezometer } = useCreatePiezometer();
  const {
    state: instrumentEspecificData,
    updateValue: setInstrumentEspecificData
  }: {
    state: any;
    updateValue: any;
  } = useReducerHook({});
  const {
    state: basicInfos,
    updateValue: setBasicInfos
  }: {
    state: any;
    updateValue: any;
  } = useReducerHook({ status: true, isUTM: true });

  const handleStepOne = () => {
    if (
      !basicInfos.name ||
      basicInfos.coordinateE === undefined ||
      basicInfos.coordinateN === undefined ||
      !basicInfos.readingType
    ) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setBasicInfosError(true);
      return false;
    }

    if (basicInfos.activationDate < basicInfos.installationDate) {
      toastfyError(t('dateActivationIsLessThanInstallationDate'));
      setBasicInfosError(true);
      return;
    }

    if (basicInfos.isUTM) {
      if (basicInfos.coordinateN < 0 || basicInfos.coordinateE < 0) {
        toastfyError(t('coordinateUtmCannottNegative'));
        setBasicInfosError(true);
        return false;
      }
    } else {
      if (basicInfos.coordinateN < -180 || basicInfos.coordinateN > 180) {
        toastfyError(t('ValidationCoordinateN'));
      }

      if (basicInfos.coordinateE < -90 || basicInfos.coordinateE > 90) {
        toastfyError(t('ValidationCoordinateE'));
      }
    }

    setBasicInfosError(false);
    return true;
  };

  const handleSubmit = async () => {
    const alertLevels = instrumentEspecificData?.alertLevels;
    const alertLevelsSurface = instrumentEspecificData?.alertLevelsSurface;
    const references = instrumentEspecificData?.references?.[0];
    const alertLevelsPv = instrumentEspecificData?.alertLevelsPv;
    const specificInfos = instrumentEspecificData?.specificInfos;
    switch (basicInfos.typeName) {
      case 'Piezometer':
        try {
          if (!specificInfos.topCote || !specificInfos.bottomCote) {
            toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
            setSpecificInfosError(true);
            return false;
          }

          const response = await addPiezometer({
            variables: {
              alertLevels: alertLevels ? alertLevels : [],
              generalInfo: {
                activationDate: basicInfos.activationDate,
                coordinateE: basicInfos.coordinateE,
                coordinateN: basicInfos.coordinateN,
                installLocation: basicInfos.installLocation,
                installationDate: basicInfos.installationDate,
                isUTM: basicInfos.isUTM,
                name: basicInfos.name,
                readingType: basicInfos.readingType,
                operationalStatus: basicInfos.status
                  ? InstrumentStatusEnum.Active
                  : InstrumentStatusEnum.Inactive,
                typeId: basicInfos.typeId,
                sectionsId: [crossSection?.id, longitudinalSection?.id].filter(
                  (id) => id
                ) as string[]
              },
              structureInfo: {
                structureId: structureId!,
                associatedStructureId: null
              },
              specificInfo: specificInfos
            },
            refetchQueries: [
              {
                query: ListInstrumentsByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!
                  }
                }
              },
              {
                query: ListPiezometersByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!,
                    associatedStructureId: null
                  }
                }
              }
            ]
          });
          if (response.errors) {
            const errorCordinate = response.errors[0].message;
            toastfyError(t(errorCordinate));
          }

          if (response.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successRegisterInstrument)
            );
            setSpecificInfosError(false);
            props.setDataChanged(true);
            return true;
          }

          setSpecificInfosError(true);
          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          setSpecificInfosError(true);
          return false;
        }

      case 'Ina':
        try {
          if (!specificInfos.topCote || !specificInfos.bottomCote) {
            toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
            setSpecificInfosError(true);
            return false;
          }

          const response = await addPiezometer({
            variables: {
              alertLevels: alertLevels ? alertLevels : [],
              generalInfo: {
                activationDate: basicInfos.activationDate,
                coordinateE: basicInfos.coordinateE,
                coordinateN: basicInfos.coordinateN,
                installLocation: basicInfos.installLocation,
                installationDate: basicInfos.installationDate,
                isUTM: basicInfos.isUTM,
                name: basicInfos.name,
                readingType: basicInfos.readingType,
                operationalStatus: basicInfos.status
                  ? InstrumentStatusEnum.Active
                  : InstrumentStatusEnum.Inactive,
                typeId: basicInfos.typeId,
                sectionsId: [crossSection?.id, longitudinalSection?.id].filter(
                  (id) => id
                ) as string[]
              },
              structureInfo: {
                structureId: structureId!,
                associatedStructureId: null
              },
              specificInfo: specificInfos
            },
            refetchQueries: [
              {
                query: ListInstrumentsByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!
                  }
                }
              },
              {
                query: ListPiezometersByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!,
                    associatedStructureId: null
                  }
                }
              }
            ]
          });
          if (response.errors) {
            const errorCordinate = response.errors[0].message;
            toastfyError(t(errorCordinate));
          }

          if (response.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successRegisterInstrument)
            );
            setSpecificInfosError(false);
            props.setDataChanged(true);
            return true;
          }

          setSpecificInfosError(true);
          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          setSpecificInfosError(true);
          return false;
        }

      case 'Pluviometer':
        try {
          const response = await addPluviometer({
            variables: {
              structureInfo: {
                structureId: structureId!,
                associatedStructureId: null
              },
              generalInfo: {
                activationDate: basicInfos.activationDate,
                coordinateE: basicInfos.coordinateE,
                coordinateN: basicInfos.coordinateN,
                installLocation: basicInfos.installLocation,
                installationDate: basicInfos.installationDate,
                isUTM: basicInfos.isUTM,
                name: basicInfos.name,
                readingType: basicInfos.readingType,
                operationalStatus: basicInfos.status
                  ? InstrumentStatusEnum.Active
                  : InstrumentStatusEnum.Inactive,
                typeId: basicInfos.typeId
              },
              alertLevels: alertLevelsPv === undefined ? [] : alertLevelsPv,
              specificInfo: {
                pluviometerType: null
              }
            },
            refetchQueries: [
              {
                query: ListInstrumentsByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!
                  }
                }
              },
              {
                query: ListPluviometersByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!,
                    associatedStructureId: null
                  }
                }
              }
            ]
          });
          if (response.errors) {
            const errorCordinate = response.errors[0].message;
            toastfyError(t(errorCordinate));
          }

          if (response.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successRegisterInstrument)
            );
            props.setDataChanged(true);
            return true;
          }

          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          return false;
        }

      case 'WaterLevel':
        try {
          const response = await addWaterLevel({
            variables: {
              structureInfo: {
                structureId: structureId!,
                associatedStructureId: null
              },
              alertLevels: alertLevels === undefined ? [] : alertLevels,
              generalInfo: {
                activationDate: basicInfos.activationDate,
                coordinateE: basicInfos.coordinateE,
                coordinateN: basicInfos.coordinateN,
                installLocation: basicInfos.installLocation,
                installationDate: basicInfos.installationDate,
                isUTM: basicInfos.isUTM,
                name: basicInfos.name,
                readingType: basicInfos.readingType,
                operationalStatus: basicInfos.status
                  ? InstrumentStatusEnum.Active
                  : InstrumentStatusEnum.Inactive,
                typeId: basicInfos.typeId
              }
            },
            refetchQueries: [
              {
                query: ListInstrumentsByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!
                  }
                }
              },
              {
                query: ListWaterLevelByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!,
                    associatedStructureId: null
                  }
                }
              }
            ]
          });
          if (response.errors) {
            const errorCordinate = response.errors[0].message;
            toastfyError(t(errorCordinate));
          }

          if (response.data) {
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successRegisterInstrument)
            );
            props.setDataChanged(true);
            return true;
          }

          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          return false;
        }

      case 'SurfaceMarker':
        if (
          !references ||
          !references.referenceElevation ||
          !references.referenceCoordinateN ||
          !references.referenceCoordinateE
        ) {
          toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
          setSpecificInfosError(true);
          return false;
        }

        if (!alertLevelsSurface) {
          toastfyError(t('addAtLessOneLevelOfControl'));
          return false;
        }

        const formattedReferences = [
          {
            elevation: references.referenceElevation,
            coordinateE: references.referenceCoordinateE,
            coordinateN: references.referenceCoordinateN,
            date: references.referenceDate
          }
        ];

        const formattedAlertLevels = alertLevelsSurface.map((alert: any) => ({
          elevation: alert.levelElevation,
          coordinateE: alert.levelCoordinateE,
          coordinateN: alert.levelCoordinateN,
          date: alert.levelDate,
          name: alert.levelName
        }));
        try {
          const response = await addSurfaceMarker({
            variables: {
              structureInfo: {
                structureId: structureId!,
                associatedStructureId: null
              },
              generalInfo: {
                activationDate: basicInfos.activationDate,
                coordinateE: basicInfos.coordinateE,
                coordinateN: basicInfos.coordinateN,
                installLocation: basicInfos.installLocation,
                installationDate: basicInfos.installationDate,
                isUTM: basicInfos.isUTM,
                name: basicInfos.name,
                readingType: basicInfos.readingType,
                operationalStatus: basicInfos.status
                  ? InstrumentStatusEnum.Active
                  : InstrumentStatusEnum.Inactive,
                typeId: basicInfos.typeId
              },
              alertLevels: formattedAlertLevels,
              references: formattedReferences
            },
            refetchQueries: [
              {
                query: ListInstrumentsByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!
                  }
                }
              },
              {
                query: ListSurfaceMarkersByStructureDocument,
                variables: {
                  structureInfo: {
                    structureId: structureId!,
                    associatedStructureId: null
                  }
                }
              }
            ]
          });
          if (response.errors) {
            const errorCordinate = response.errors[0].message;
            toastfyError(t(errorCordinate));
          }

          if (response.data) {
            props.setDataChanged(true);
            toastfyDimiss('toastLoading');
            toastfySuccess(
              t(ToastifyModel().toastifyMessage.successRegisterInstrument)
            );
            return true;
          }

          return false;
        } catch (e) {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          return false;
        }

      default:
        return false;
    }
  };

  useEffect(() => {
    if (!listInstruments?.listInstrumentTypes) return;
    const foundType = listInstruments?.listInstrumentTypes.find(
      (type) =>
        type.instrumentType.toLocaleUpperCase() ===
        instrumentType?.toLocaleUpperCase()
    );
    if (!foundType) return;
    setBasicInfos('typeId', foundType.id);
    setBasicInfos('typeName', foundType.instrumentType);
  }, [instrumentType, listInstruments]);

  return (
    <ModalStep
      style={{ padding: '20px' }}
      title={`${t('AddInstrument')} ${t(basicInfos.typeName)}`}
      onClose={() => setShowModal(false)}
      steps={[
        {
          name: 'BasicInformation',
          component: (
            <RegisterBasicInfos
              installationDate={installationDate}
              setInstallDate={setInstallationDate}
              activationDate={activactionDate}
              setReadingModeSelected={setReadingModeSelected}
              readingModeSelected={readingModeSelected}
              setActivationDate={setActivactionDate}
              setSubStructureSelected={setSubStructureSelected}
              subStructureSelected={subStructureSelected}
              setInstallationLocationSelected={setInstallationLocationSelected}
              installationLocationSelected={installationLocationSelected}
              setBasicInfos={setBasicInfos}
              basicInfos={basicInfos}
              basicInfosError={basicInfosError}
            />
          ),
          onNext: () => handleStepOne()
        },
        {
          name: 'InstrumentInformation',
          component: (
            <RegisterInstrumentInfos
              isEditing={false}
              instrumentEspecificData={instrumentEspecificData}
              setInstrumentEspecificData={setInstrumentEspecificData}
              basicInfos={basicInfos}
              instrumentSelected={basicInfos.typeName}
              specificInfosError={specificInfosError}
              crossSection={crossSection}
              longitudinalSection={longitudinalSection}
              setCrossSection={setCrossSection}
              setLongitudinalSection={setLongitudinalSection}
            />
          ),
          onNext: () => handleSubmit()
        }
      ]}
    />
  );
};
