import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { z, ZodIssueCode } from 'zod';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Text from '../../Atoms/Text/Text';
import InputText from '../../Molecules/InputText/InputText';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum
} from '../../../../data/graphql/base-schema';
import Enums from '../../../../utils/enumns';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import {
  EditPiezometerDocument,
  EditPiezometerMutation,
  EditPiezometerMutationVariables
} from '../../../../data/graphql/generated/editPiezometer.mutation';
import { ListPiezometersByStructureDocument } from '../../../../data/graphql/query/generated/listPiezometersByStructure.query';
import {
  BorderContainer,
  BorderContainerSpecificInfos,
  DivButton,
  DivInputs,
  HolderAlertLevel,
  HolderCommonInfos,
  HolderContainerTitle,
  HolderControlQuotas,
  HolderSpecificsInfo,
  MainContainer,
  DivButtonAlert,
  HolderTitle
} from './EditPiezometerAndInaPage.styles';
import {
  ListInstrumentByIdDocument,
  ListInstrumentByIdQuery,
  ListInstrumentByIdQueryVariables
} from '../../../../data/graphql/query/generated/listInstrumentsById';
import {
  ListControlQuotasByPiezometersPagDocument,
  ListControlQuotasByPiezometersPagQuery,
  ListControlQuotasByPiezometersPagQueryVariables
} from '../../../../data/graphql/query/generated/listControlQuotasByPiezometersPag.query';
import {
  ListAlertsLevelByPiezometersPagDocument,
  ListAlertsLevelByPiezometersPagQuery,
  ListAlertsLevelByPiezometersPagQueryVariables
} from '../../../../data/graphql/query/generated/listAlertsLevelByPiezometersPag.query';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import { useState } from 'react';
import { BiSolidPencil } from 'react-icons/bi';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import EditPiezometerAndInaControlQuotas from '../../Organisms/EditPiezometerAndInaControlQuotas/EditPiezometerAndInaControlQuotas';
import PiezometerAndInaAlertLeves from '../../Organisms/PiezometerAndInaAlertLeves/PiezometerAndInaAlertLeves';
import {
  alertLevelDataProps,
  alertLevelProps,
  controlQuotasDataProps,
  controlQuotasProps
} from './EditPiezometerAndInaPage.interface';
import { FaTrashCan } from 'react-icons/fa6';
import {
  DeleteInstrumentAlertLevelDocument,
  DeleteInstrumentAlertLevelMutation,
  DeleteInstrumentAlertLevelMutationVariables
} from '../../../../data/graphql/generated/deleteInstrumentAlertLevel.mutation';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import {
  ListSectionsByStructureDocument,
  ListSectionsByStructureQuery,
  ListSectionsByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSectionsByStructure.query';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import { GroupText } from '../../Molecules/GroupText/GroupText';

const EditPiezometerAndInaPage = () => {
  const { t: translate } = useTranslation();
  const { instrumentId, structureId } = useParams();
  const { OperationalStatus, TypeOfReading, TypeOfInstallation } = Enums();

  const [pageControl, setPageControl] = useState<number>(1);
  const [pageAlerts, setPageAlerts] = useState<number>(1);
  const [modalAlert, setModalAlert] = useState<alertLevelProps>({
    show: false,
    data: {
      id: '',
      name: '',
      drained: '',
      undrained: '',
      coteValue: 0
    }
  });
  const [modalControlQuotas, setModalControlQuotas] =
    useState<controlQuotasProps>({
      show: false,
      data: {
        id: '',
        topCote: undefined,
        bottomCote: undefined,
        referenceDate: undefined
      }
    });
  const [deleteAlertLevel, setDeleteAlertLevel] = useState<string | null>(null);

  const canEdit = location.pathname.includes('edit');

  const [getInstumentData] = useLazyQuery<
    ListInstrumentByIdQuery,
    ListInstrumentByIdQueryVariables
  >(ListInstrumentByIdDocument);

  const [deleteInstrumentAlertLevel] = useMutation<
    DeleteInstrumentAlertLevelMutation,
    DeleteInstrumentAlertLevelMutationVariables
  >(DeleteInstrumentAlertLevelDocument);

  const [editPiezometer] = useMutation<
    EditPiezometerMutation,
    EditPiezometerMutationVariables
  >(EditPiezometerDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [ListPiezometersByStructureDocument]
  });

  const { data: instrumentAlertsLevelData } = useQuery<
    ListAlertsLevelByPiezometersPagQuery,
    ListAlertsLevelByPiezometersPagQueryVariables
  >(ListAlertsLevelByPiezometersPagDocument, {
    variables: {
      instrumentId: instrumentId!,
      pageInfo: {
        page: pageAlerts,
        limit: 3
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const { data: instrumentControlQuotasData } = useQuery<
    ListControlQuotasByPiezometersPagQuery,
    ListControlQuotasByPiezometersPagQueryVariables
  >(ListControlQuotasByPiezometersPagDocument, {
    variables: {
      instrumentId: instrumentId!,
      pageInfo: {
        page: pageControl,
        limit: 3
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const { data: listSections } = useQuery<
    ListSectionsByStructureQuery,
    ListSectionsByStructureQueryVariables
  >(ListSectionsByStructureDocument, {
    variables: {
      structureId: structureId!
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const listSectionsData: OptionType[] =
    listSections?.listSectionsByStructure.map((section) => ({
      value: section.id,
      label: section.name
    })) || [];

  const basicInfoInstrumentSchema = z
    .object({
      name: z.string().min(1, translate('fieldIsRequired')),
      coordinateE: z.string(),
      coordinateN: z.string(),
      installationDate: z.date().nullable(),
      activationDate: z.date().nullable(),
      operationalStatus: z
        .object({
          value: z.string(),
          label: z.string()
        })
        .nullable()
        .refine((val) => val !== null, {
          message: translate('fieldIsRequired')
        }),
      deactivationDate: z.date().nullable().optional(),
      installLocation: z.object({
        value: z.string(),
        label: z.string()
      }),
      readingType: z
        .object({
          value: z.string(),
          label: z.string()
        })
        .nullable()
        .refine((val) => val !== null, {
          message: translate('fieldIsRequired')
        }),
      isUTM: z.boolean(),
      sectionsId: z
        .object({
          value: z.string().optional(),
          label: z.string().optional()
        })
        .optional()
    })
    .superRefine((data, ctx) => {
      const {
        installationDate,
        activationDate,
        coordinateE,
        coordinateN,
        isUTM,
        operationalStatus,
        deactivationDate
      } = data;

      if (!installationDate && activationDate) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['installationDate'],
          message: translate('fieldIsRequired')
        });
      }

      if (
        activationDate &&
        installationDate &&
        activationDate < installationDate
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['activationDate'],
          message: translate('dateActivationIsLessThanInstallationDate')
        });
      }

      if (
        !isUTM &&
        (parseInt(coordinateE) > 90 || parseInt(coordinateE) < -90)
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateE'],
          message: translate('ValidationCoordinateE')
        });
      }

      if (
        !isUTM &&
        (parseInt(coordinateN) > 180 || parseInt(coordinateN) < -180)
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateN'],
          message: translate('ValidationCoordinateN')
        });
      }

      if (isUTM && parseInt(coordinateE) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateE'],
          message: translate('coordinateUtmCannottNegative')
        });
      }

      if (isUTM && parseInt(coordinateN) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateN'],
          message: translate('coordinateUtmCannottNegative')
        });
      }

      if (operationalStatus.value === 'Inactive' && !deactivationDate) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['deactivationDate'],
          message: translate('fieldIsRequired')
        });
      }

      if (
        operationalStatus.value === 'Inactive' &&
        deactivationDate &&
        activationDate &&
        deactivationDate < activationDate
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['deactivationDate'],
          message: translate('dateDeactivationNeedToBeGreaterThanActivation')
        });
      }
    });

  type instrumentCommonInfosType = z.infer<typeof basicInfoInstrumentSchema>;

  const totalPagesAlertsLevel =
    instrumentAlertsLevelData?.listAlertsLevelByPiezometersPag.pageInfo
      .totalPages;
  const alertsLevelData =
    instrumentAlertsLevelData?.listAlertsLevelByPiezometersPag.nodes.map(
      (alert) => ({
        coteValue: alert.coteValue,
        drained: alert.drained,
        id: alert.id,
        name: alert.name,
        undrained: alert.undrained
      })
    ) || [];
  const alertsLevelColumnData = [
    {
      key: 'name',
      label: translate('name')
    },
    {
      key: 'coteValue',
      label: translate('coteValue')
    },
    {
      key: 'drained',
      label: translate('Drained')
    },
    {
      key: 'undrained',
      label: translate('NotDrained')
    }
  ];
  const ActionsAlertLevel: AvailableContextActionsProps<alertLevelDataProps>[] =
    [
      {
        name: translate('Edit'),
        onClick: (data: alertLevelDataProps) => handleEditAlertLevel(data),
        icon: <BiSolidPencil />,
        canShow: 'canEdit'
      },
      {
        name: translate('Delete'),
        onClick: (data: alertLevelDataProps) => setDeleteAlertLevel(data.id),
        icon: <FaTrashCan />,
        canShow: 'canDelete'
      }
    ];

  const totalPagesControlQuotas =
    instrumentControlQuotasData?.listControlQuotasByPiezometersPag.pageInfo
      .totalPages;
  const ControlQuotasData =
    instrumentControlQuotasData?.listControlQuotasByPiezometersPag.nodes.map(
      (controlQuota) => ({
        topCote: controlQuota.topCote,
        bottomCote: controlQuota.bottomCote,
        id: controlQuota.id,
        referenceDate: controlQuota.referenceDate,
        refDate: new Date(controlQuota.referenceDate).toLocaleDateString()
      })
    ) || [];
  const ControlQuotasColumnData = [
    {
      key: 'topCote',
      label: translate('topCote')
    },
    {
      key: 'bottomCote',
      label: translate('bottomCote')
    },
    {
      key: 'refDate',
      label: translate('ReferenceDate')
    }
  ];
  const actionsControlQuotas: AvailableContextActionsProps<controlQuotasDataProps>[] =
    [
      {
        name: translate('Edit'),
        onClick: (data: controlQuotasDataProps) =>
          handleEditControlQuotas(data),
        icon: <BiSolidPencil />,
        canShow: 'canEdit'
      }
    ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch
  } = useForm<instrumentCommonInfosType>({
    resolver: zodResolver(basicInfoInstrumentSchema),
    defaultValues: async () => {
      const { data } = await getInstumentData({
        variables: { instrumentId: instrumentId! },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        }
      });

      if (data) {
        return {
          name: data?.listInstrumentById.name,
          coordinateE: data?.listInstrumentById?.coordinateE.toString(),
          coordinateN: data?.listInstrumentById?.coordinateN.toString(),
          isUTM: data?.listInstrumentById?.isUTM,
          activationDate: data?.listInstrumentById?.activationDate
            ? new Date(data?.listInstrumentById?.activationDate)
            : null,
          installationDate: data?.listInstrumentById?.installationDate
            ? new Date(data?.listInstrumentById?.installationDate)
            : null,
          operationalStatus: {
            value:
              (data?.listInstrumentById
                .operationalStatus as unknown as string) || '',
            label:
              (data?.listInstrumentById
                .operationalStatus as unknown as string) || ''
          },
          deactivationDate: data?.listInstrumentById?.deactivationDate
            ? new Date(data?.listInstrumentById?.deactivationDate)
            : null,
          installLocation: {
            value: data?.listInstrumentById.installLocation || '',
            label: data?.listInstrumentById.installLocation || ''
          },
          readingType: {
            value: data?.listInstrumentById.readingType,
            label: data?.listInstrumentById.readingType
          },
          sectionsId: {
            value: data?.listInstrumentById.sections[0]?.id,
            label: data?.listInstrumentById.sections[0]?.name
          }
        };
      } else {
        return {
          name: '',
          coordinateE: '',
          coordinateN: '',
          isUTM: false,
          activationDate: null,
          installationDate: null,
          operationalStatus: {
            value: '',
            label: ''
          },
          deactivationDate: null,
          installLocation: {
            value: '',
            label: ''
          },
          readingType: {
            value: '',
            label: ''
          },
          sectionsId: {
            value: '',
            label: ''
          }
        };
      }
    }
  });

  const handleEditPiezometer = (dataFromForm: instrumentCommonInfosType) => {
    const piezometer = {
      instrumentId: instrumentId!,
      name: dataFromForm.name,
      coordinateE: Number(dataFromForm.coordinateE),
      coordinateN: Number(dataFromForm.coordinateN),
      isUTM: dataFromForm.isUTM,
      operationalStatus: dataFromForm.operationalStatus
        .value as InstrumentStatusEnum,
      readingType: dataFromForm.readingType.value as InstrumentReadingTypeEnum,
      activationDate: dataFromForm.activationDate,
      deactivationDate:
        dataFromForm.operationalStatus.value === 'Active'
          ? null
          : dataFromForm.deactivationDate,
      installationDate: dataFromForm.installationDate,
      installLocation: dataFromForm.installLocation.value,
      sectionsId: dataFromForm.sectionsId?.value
        ? [dataFromForm.sectionsId.value]
        : []
    };
    editPiezometer({
      variables: {
        structureInfo: {
          structureId: structureId!,
          associatedStructureId: ''
        },
        data: {
          generalInfo: piezometer
        }
      },
      refetchQueries: [
        {
          query: ListPiezometersByStructureDocument,
          variables: {
            structureInfo: {
              structureId: structureId!,
              associatedStructureId: null
            }
          }
        },
        {
          query: ListInstrumentByIdDocument,
          variables: {
            instrumentId: instrumentId!
          }
        }
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toastfySuccess(translate('updatedSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  const handleEditAlertLevel = (data: alertLevelDataProps) => {
    setModalAlert({ show: true, data });
  };

  const handleAddAlertLevel = () => {
    setModalAlert({
      show: true,
      data: {
        id: '',
        name: '',
        drained: '',
        undrained: '',
        coteValue: 0
      }
    });
  };

  const handleAddControlQuotas = () => {
    setModalControlQuotas({
      show: true,
      data: {
        id: '',
        topCote: undefined,
        bottomCote: undefined,
        referenceDate: undefined
      }
    });
  };

  const handleEditControlQuotas = (data: controlQuotasDataProps) => {
    setModalControlQuotas({ show: true, data });
  };

  const handleDeleteAlertLevel = (id: string) => {
    deleteInstrumentAlertLevel({
      variables: {
        structureId: structureId!,
        data: {
          instrumentId: instrumentId!,
          alertId: id
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ListInstrumentByIdDocument,
          variables: { instrumentId: instrumentId! }
        },
        ListAlertsLevelByPiezometersPagDocument
      ],
      onCompleted: () => {
        toastfySuccess(translate('deletedSuccessfully'));
        setDeleteAlertLevel(null);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
        setDeleteAlertLevel(null);
      }
    });
  };

  return (
    <>
      <MainContainer>
        <HolderCommonInfos>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('basicInfos')}
            </Text>
          </HolderContainerTitle>
          {canEdit ? (
            <BorderContainer>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('name')}
                  name={'name'}
                  type={'text'}
                  error={!!errors.name}
                  errorMessage={errors?.name?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('coordinateE')}
                  name={'coordinateE'}
                  type={'number'}
                  error={!!errors.coordinateE}
                  errorMessage={errors?.coordinateE?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('coordinateN')}
                  name={'coordinateN'}
                  type={'number'}
                  error={!!errors.coordinateN}
                  errorMessage={errors?.coordinateN?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputCheckbox
                  register={register}
                  label={translate('coordinateUTM')}
                  name={'isUTM'}
                  value={watch('isUTM')}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('installationDate')}
                  name="installationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.installationDate}
                  errorMessage={errors?.installationDate?.message}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('activationDate')}
                  name="activationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.activationDate}
                  errorMessage={errors?.activationDate?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('operationalStatus')}
                  width="300px"
                  label={translate('operationalStatus')}
                  options={OperationalStatus}
                  name="operationalStatus"
                  error={!!errors.operationalStatus}
                  errorMessage={errors?.operationalStatus?.message}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('deactivationDate')}
                  name="deactivationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.deactivationDate}
                  errorMessage={errors?.deactivationDate?.message}
                  disabled={watch('operationalStatus')?.value === 'Active'}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('installLocation')}
                  width="300px"
                  label={translate('installLocation')}
                  options={TypeOfInstallation}
                  name="installLocation"
                  error={!!errors.installLocation}
                  errorMessage={errors?.installLocation?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('typeOfReading')}
                  width="300px"
                  label={translate('typeOfReading')}
                  options={TypeOfReading}
                  name="readingType"
                  error={!!errors.readingType}
                  errorMessage={errors?.readingType?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('Section')}
                  width="300px"
                  label={translate('Section')}
                  options={listSectionsData}
                  value={listSectionsData.find(
                    (section) => section.value === watch('sectionsId')?.value
                  )}
                  name="sectionsId"
                  error={!!errors.sectionsId}
                  errorMessage={errors?.sectionsId?.message}
                />
              </DivInputs>
              <DivButton>
                <Button
                  variant="primary"
                  onClick={handleSubmit(handleEditPiezometer)}
                  text={translate('saveGeneralData')}
                />
              </DivButton>
            </BorderContainer>
          ) : (
            <BorderContainer>
              <DivInputs>
                <GroupText title={translate('name')} value={watch('name')} />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateE')}
                  value={watch('coordinateE')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateN')}
                  value={watch('coordinateN')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateUTM')}
                  value={watch('isUTM') ? translate('Yes') : translate('No')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('installationDate')}
                  value={
                    watch('installationDate')
                      ? new Date(
                          watch('installationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('activationDate')}
                  value={
                    watch('activationDate')
                      ? new Date(
                          watch('activationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('operationalStatus')}
                  value={translate(
                    watch('operationalStatus')
                      ? watch('operationalStatus').label
                      : '-'
                  )}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('deactivationDate')}
                  value={
                    watch('deactivationDate')
                      ? new Date(
                          watch('deactivationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('installLocation')}
                  value={translate(
                    watch('installLocation')?.value
                      ? watch('installLocation').value
                      : '-'
                  )}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('typeOfReading')}
                  value={translate(
                    watch('readingType') ? watch('readingType').value : '-'
                  )}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('Section')}
                  value={watch('sectionsId')?.label || '-'}
                />
              </DivInputs>
            </BorderContainer>
          )}
        </HolderCommonInfos>
        <HolderSpecificsInfo>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('instrumentInformation')}
            </Text>
          </HolderContainerTitle>
          <BorderContainerSpecificInfos>
            <HolderAlertLevel>
              <HolderTitle>
                <Text color="white" type="span" weight="default">
                  {translate('alertLevels')}
                </Text>
              </HolderTitle>
              <TableComponent
                totalPages={totalPagesAlertsLevel || 1}
                tableData={alertsLevelData}
                columns={alertsLevelColumnData}
                actions={canEdit ? ActionsAlertLevel : undefined}
                paginator
                pageState={{
                  currentPage: pageAlerts,
                  totalPages: totalPagesAlertsLevel || 1,
                  nextPage: () => setPageAlerts(pageAlerts + 1),
                  previousPage: () => setPageAlerts(pageAlerts - 1)
                }}
              />
              <DivButtonAlert>
                {canEdit && (
                  <Button
                    variant="primary"
                    text={translate('newAlertLevel')}
                    onClick={() => handleAddAlertLevel()}
                  />
                )}
              </DivButtonAlert>
            </HolderAlertLevel>
            <HolderControlQuotas>
              <HolderTitle>
                <Text color="white" type="span" weight="default">
                  {translate('controlCotes')}
                </Text>
              </HolderTitle>
              <TableComponent
                totalPages={totalPagesControlQuotas || 1}
                tableData={ControlQuotasData}
                columns={ControlQuotasColumnData}
                actions={canEdit ? actionsControlQuotas : undefined}
                paginator
                pageState={{
                  currentPage: pageControl,
                  totalPages: totalPagesControlQuotas || 1,
                  nextPage: () => setPageControl(pageControl + 1),
                  previousPage: () => setPageControl(pageControl - 1)
                }}
              />
              <DivButtonAlert>
                {canEdit && (
                  <Button
                    variant="primary"
                    text={translate('newControlQuotas')}
                    onClick={() => handleAddControlQuotas()}
                  />
                )}
              </DivButtonAlert>
            </HolderControlQuotas>
          </BorderContainerSpecificInfos>
        </HolderSpecificsInfo>
      </MainContainer>

      {modalAlert.show && (
        <ViewModal
          onClose={() =>
            setModalAlert({
              show: false,
              data: {
                id: '',
                name: '',
                drained: '',
                undrained: '',
                coteValue: 0
              }
            })
          }
          title={
            modalAlert.data.id
              ? translate('editAlertlevel')
              : translate('newAlertLevel')
          }
          component={
            <PiezometerAndInaAlertLeves
              data={modalAlert.data}
              setModalAlert={setModalAlert}
            />
          }
          width="auto"
          height="auto"
        />
      )}

      {modalControlQuotas.show && (
        <ViewModal
          onClose={() =>
            setModalControlQuotas({
              show: false,
              data: {
                id: '',
                topCote: undefined,
                bottomCote: undefined,
                referenceDate: undefined
              }
            })
          }
          title={
            modalControlQuotas.data.id
              ? translate('editControlQuotas')
              : translate('newControlQuotas')
          }
          component={
            <EditPiezometerAndInaControlQuotas
              data={modalControlQuotas.data}
              setModalControlQuotas={setModalControlQuotas}
            />
          }
          width="min(400px, 100%)"
          height="min(600px, 100%)"
        />
      )}

      {deleteAlertLevel && (
        <ModalConfirm
          text={translate('ConfirmDelete')}
          subtitle={translate('ActionCannotBeUndone')}
          title={translate('deleteAlertLevel')}
          onClose={() => setDeleteAlertLevel(null)}
          onConfirm={() => handleDeleteAlertLevel(deleteAlertLevel)}
        />
      )}
    </>
  );
};

export default EditPiezometerAndInaPage;
