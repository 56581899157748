import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InstrumentSelectInterface } from '../../../../@Types/Instruments/instruments';
import { useReducerHook } from '../../../../Hooks/ReducerHook';
import { InstrumentsType } from '../../../../Models/InstrumentsModel';
import { ReadingsModel } from '../../../../Models/Readings/ReadingsModel';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { useCreatePiezometerReading } from '../../../../data/hooks/piezometer/use-create-piezometer-reading.mutation';
import { useListPiezometersByStructureLazy } from '../../../../data/hooks/piezometer/use-list-piezometers-by-structure.query';
import { useCreatePluviometerReading } from '../../../../data/hooks/pluviometer/use-create-pluviometer-reading.mutation';
import { useListPluviometersByStructureLazy } from '../../../../data/hooks/pluviometer/use-list-pluviometers-by-structure';
import { useCreateSurfaceMarkerReading } from '../../../../data/hooks/surface-marker/use-create-surface-marker-reading.mutation';
import { useListSurfaceMarkersByStructureLazy } from '../../../../data/hooks/surface-marker/use-list-surface-markers-by-structure.query';
import { useCreateWaterLevelReading } from '../../../../data/hooks/water-level/use-create-water-level-reading.mutation';
import { useListWaterLevelByStructureLazy } from '../../../../data/hooks/water-level/use-list-water-level-by-structure';
import { AdvanceButtonStyled } from '../../../Buttons/ButtonsStyle';
import { CancelButtonComponent } from '../../../Buttons/Instruments/CancelButton';
import { DatePickerComponent } from '../../../DatePicker';
import GraphHeader from '../../../Graphs/Monitoring/Instrument/GraphHeader/GraphHeader';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import InstrumentSelects from '../../../Selects/Instruments/InstrumentsSelects';
import { TextAreaComponent } from '../../../TextAreaComponent';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentModal
} from '../../Register/RegisterInstrumentStyle';
import { DataBaseButtonArea, RegisterReadingsArea } from '../DataBaseStyles';
import InputCheckbox from '../../../V2/Molecules/InputCheckbox/InputCheckbox';

export const RegisterReadingsModal = ({
  type,
  showModal,
  setShowModal,
  createdReading,
  setCreatedReading,
  setCountPie,
  setCountPlu,
  setCountWL,
  setCountSM
}: {
  type: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  createdReading: boolean;
  setCreatedReading: Dispatch<SetStateAction<boolean>>;
  setCountPie: Dispatch<SetStateAction<number>>;
  setCountPlu: Dispatch<SetStateAction<number>>;
  setCountWL: Dispatch<SetStateAction<number>>;
  setCountSM: Dispatch<SetStateAction<number>>;
}) => {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const [instrumentSelected, setInstrumentSelected] =
    useState<InstrumentSelectInterface | null>(null);
  const [isDry, setIsDry] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [observations, setObservations] = useState<string>('');
  const { state, updateValue } = useReducerHook({});
  const [instrumentList, setInstrumentList] = useState<any[]>([]);
  const { createReading: createReadingPiezometer } =
    useCreatePiezometerReading();
  const { createReading: createReadingPluviometer } =
    useCreatePluviometerReading();
  const { createReading: createReadingWaterLevel } =
    useCreateWaterLevelReading();
  const { createReading: createReadingSurfaceMarker } =
    useCreateSurfaceMarkerReading();
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const { listPiezometers } = useListPiezometersByStructureLazy();
  const { listPluviometers } = useListPluviometersByStructureLazy();
  const { listWaterLevels } = useListWaterLevelByStructureLazy();
  const { listSurfaceMarkers } = useListSurfaceMarkersByStructureLazy();

  const isReadingValid = state.Reading !== undefined && state.Reading !== '';
  const isQuotaValid = state.Quota !== undefined && state.Quota !== '';

  async function handleFetchPiezometers() {
    const response = await listPiezometers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(
        response.data.listPiezometersByStructure.filter(
          (item) =>
            item.type.instrumentType.toLocaleLowerCase() === 'piezometer'
        )
      );
    }
  }

  async function handleFetchInas() {
    const response = await listPiezometers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(
        response.data.listPiezometersByStructure.filter(
          (item) => item.type.instrumentType.toLocaleLowerCase() === 'ina'
        )
      );
    }
  }

  async function handleFetchPluviometers() {
    const response = await listPluviometers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listPluviometersByStructure);
    }
  }

  async function handleFetchWaterLevels() {
    const response = await listWaterLevels({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listWaterLevelByStructure);
    }
  }

  async function handleFetchSurfaceMarkers() {
    const response = await listSurfaceMarkers({
      variables: {
        structureInfo
      }
    });
    toastifyLoading('Carregando Instrumentos...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      setInstrumentList(response.data.listSurfaceMarkersByStructure);
    }
  }

  const handleFetchSelectedInstrumentList = (type: string) => {
    const strategy = {
      piezometer: () => handleFetchPiezometers(),
      ina: () => handleFetchInas(),
      pluviometer: () => handleFetchPluviometers(),
      waterlevel: () => handleFetchWaterLevels(),
      surfacemarker: () => handleFetchSurfaceMarkers()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  async function handleSavePiezometer() {
    if (instrumentSelected?.label && (state.Quota || state.Reading || isDry)) {
      toastifyLoading(translate('registering'));
      await createReadingPiezometer({
        variables: {
          structureInfo: structureInfo,
          data: {
            cote:
              state.Quota && state.Quota !== '' ? Number(state.Quota) : null,
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            isDry: state.isDry,
            observation: state.observations,
            reading:
              state.Reading && state.Reading !== ''
                ? Number(state.Reading)
                : null
          }
        },
        onCompleted: (data) => {
          if (data.createPiezometerReading.length > 0) {
            toastfyDimiss('toastLoading');
            toastfyError(data.createPiezometerReading[0].error || '');
            return;
          }

          toastfySuccess(translate('successRegisterReading'));
          setCountPie((prev) => prev + 1);
          setShowModal(false);
          setCreatedReading(!createdReading);
          toastfyDimiss('toastfySuccess');
        },
        onError: (error) => {
          toastfyError(
            error.message || translate(ToastifyModel().toastifyMessage.error)
          );
        }
      });
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  }

  async function handleSavePluviometer() {
    if (instrumentSelected?.label && state.Reading) {
      toastifyLoading(translate('registering'));
      await createReadingPluviometer({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: state.observations,
            rainfall: Number(state.Reading)
          }
        },
        onCompleted: (data) => {
          if (data.createPluviometerReading.length > 0) {
            toastfyDimiss('toastLoading');
            toastfyError(data.createPluviometerReading[0].error || '');
            return;
          }

          toastfySuccess(translate('successRegisterReading'));
          setCountPlu((prev) => prev + 1);
          setShowModal(false);
          setCreatedReading(!createdReading);
          toastfyDimiss('toastfySuccess');
        },
        onError: (error) => {
          toastfyError(
            error.message || translate(ToastifyModel().toastifyMessage.error)
          );
        }
      });
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  }

  async function handleSaveWaterLevel() {
    if (instrumentSelected?.label) {
      toastifyLoading(translate('registering'));
      await createReadingWaterLevel({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: state.observations,
            value: Number(state.Reading)
          }
        },
        onCompleted: (data) => {
          if (data.createWaterLevelReading.length > 0) {
            toastfyDimiss('toastLoading');
            toastfyError(data.createWaterLevelReading[0].error || '');
            return;
          }

          toastfySuccess(translate('successRegisterReading'));
          setCountWL((prev) => prev + 1);
          setShowModal(false);
          setCreatedReading(!createdReading);
          toastfyDimiss('toastfySuccess');
        },
        onError: (error) => {
          toastfyError(
            error.message || translate(ToastifyModel().toastifyMessage.error)
          );
        }
      });
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  }

  async function handleSaveSurfaceMarker() {
    if (instrumentSelected?.label) {
      toastifyLoading(translate('registering'));
      await createReadingSurfaceMarker({
        variables: {
          structureInfo: structureInfo,
          data: {
            date: state.date,
            instrumentId: instrumentSelected?.id,
            instrumentName: instrumentSelected?.label,
            observation: state.observations,
            coordinateE: Number(state.N),
            coordinateN: Number(state.E),
            elevation: Number(state.Elevation)
          }
        },
        onCompleted: (data) => {
          if (data.createSurfaceMarkersReading.length > 0) {
            toastfyDimiss('toastLoading');
            toastfyError(data.createSurfaceMarkersReading[0].error || '');
            return;
          }

          toastfySuccess(translate('successRegisterReading'));
          setCountSM((prev) => prev + 1);
          setShowModal(false);
          setCreatedReading(!createdReading);
          toastfyDimiss('toastfySuccess');
        },
        onError: (error) => {
          toastfyError(
            error.message || translate(ToastifyModel().toastifyMessage.error)
          );
        }
      });
    } else {
      toastfyError(
        translate(ToastifyModel().toastifyMessage.fillRequiredFields)
      );
    }
  }

  const handleSaveReading = (type: string) => {
    const strategy = {
      piezometer: () => handleSavePiezometer(),
      ina: () => handleSavePiezometer(),
      pluviometer: () => handleSavePluviometer(),
      waterlevel: () => handleSaveWaterLevel(),
      surfacemarker: () => handleSaveSurfaceMarker()
    };

    strategy[type.toLowerCase() as keyof typeof strategy]();
  };

  useEffect(() => {
    handleFetchSelectedInstrumentList(type);
  }, []);

  useMemo(() => {
    if (
      (type as InstrumentsType) === 'Piezometer' ||
      (type as InstrumentsType) === 'INA'
    ) {
      updateValue('isDry', isDry);
      updateValue('observations', observations);
    }
  }, [isDry, observations]);

  useMemo(() => {
    updateValue('date', date);
  }, [date]);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '50%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '300px'
        }}
      >
        <GraphHeader
          showModal={showModal}
          setShowModal={setShowModal}
          title="RegisterReadings"
          subtitle={type}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RegisterReadingsArea>
            {InstrumentSelects.InstrumentsName({
              width: 79,
              placeholder: translate('Instruments'),
              options: instrumentList.map((item) => ({
                label: translate(`${item.name}`),
                value: item.id,
                id: item.id,
                instrumentType: item.type.instrumentType
              })),
              setSelected: setInstrumentSelected,
              selected: instrumentSelected
            })}
            {(() => {
              switch (type as InstrumentsType) {
                case 'Piezometer':
                  return (
                    <>
                      {new ReadingsModel().piezometer.inputs.map(
                        (input, index) => {
                          if (input === 'Reading') {
                            return (
                              <DefaultInput
                                onChange={(event) =>
                                  updateValue(input, event.target.value)
                                }
                                key={index}
                                type="number"
                                placeholder={translate(input)}
                                disabled={isQuotaValid || isDry}
                                value={state.Reading}
                              />
                            );
                          }

                          if (input === 'Quota') {
                            return (
                              <DefaultInput
                                onChange={(event) =>
                                  updateValue(input, event.target.value)
                                }
                                key={index}
                                type="number"
                                placeholder={translate(input)}
                                disabled={isReadingValid || isDry}
                                value={state.Quota}
                              />
                            );
                          }
                        }
                      )}
                      <div
                        style={{
                          width: '76%',
                          marginTop: 0,
                          marginBottom: 13,
                          display: 'flex'
                        }}
                      >
                        <InputCheckbox
                          label={translate('Dry')}
                          name="isDry"
                          onChange={() => {
                            updateValue('Quota', '');
                            updateValue('Reading', '');
                          }}
                          value={isDry}
                          setValue={() => setIsDry(!isDry)}
                        />
                      </div>
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'INA':
                  return (
                    <>
                      {new ReadingsModel().piezometer.inputs.map(
                        (input, index) => {
                          if (input === 'Reading') {
                            return (
                              <DefaultInput
                                onChange={(event) =>
                                  updateValue(input, event.target.value)
                                }
                                key={index}
                                type="number"
                                placeholder={translate(input)}
                                disabled={isQuotaValid || isDry}
                                value={state.Reading}
                              />
                            );
                          }

                          if (input === 'Quota') {
                            return (
                              <DefaultInput
                                onChange={(event) =>
                                  updateValue(input, event.target.value)
                                }
                                key={index}
                                type="number"
                                placeholder={translate(input)}
                                disabled={isReadingValid || isDry}
                                value={state.Quota}
                              />
                            );
                          }
                        }
                      )}
                      <div
                        style={{
                          width: '76%',
                          marginTop: 0,
                          marginBottom: 13,
                          display: 'flex'
                        }}
                      >
                        <InputCheckbox
                          label={translate('Dry')}
                          name="isDry"
                          onChange={() => {
                            updateValue('Quota', '');
                            updateValue('Reading', '');
                          }}
                          value={isDry}
                          setValue={() => setIsDry(!isDry)}
                        />
                      </div>
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginBottom: 100,
                          height: '70px'
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'Pluviometer':
                  return (
                    <>
                      {' '}
                      {new ReadingsModel().pluviometer.inputs.map(
                        (input, index) => (
                          <DefaultInput
                            onChange={(event) =>
                              updateValue(input, event.target.value)
                            }
                            key={index}
                            type="number"
                            placeholder={translate(input)}
                          />
                        )
                      )}
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          height: '100px',
                          marginTop: 0,
                          marginBottom: 70
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'WaterLevel':
                  return (
                    <>
                      {' '}
                      {new ReadingsModel().reservoirWaterLevel.inputs.map(
                        (input, index) => (
                          <DefaultInput
                            onChange={(event) =>
                              updateValue(input, event.target.value)
                            }
                            key={index}
                            type="number"
                            placeholder={translate(input)}
                          />
                        )
                      )}
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          marginTop: 0,
                          marginBottom: 135
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                case 'SurfaceMarker':
                  return (
                    <>
                      {' '}
                      {new ReadingsModel().surfaceLandmark.inputs.map(
                        (input, index) => (
                          <DefaultInput
                            onChange={(event) =>
                              updateValue(input, event.target.value)
                            }
                            key={index}
                            type="number"
                            placeholder={translate(input)}
                          />
                        )
                      )}
                      <div
                        style={{
                          width: '76%',
                          fontSize: 14,
                          height: '120px',
                          marginTop: 0,
                          marginBottom: 50
                        }}
                      >
                        {translate('Date')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                        />
                      </div>
                      <div
                        style={{
                          position: 'relative',
                          width: '76%',
                          height: '100px',
                          fontSize: 14,
                          bottom: 130
                        }}
                      >
                        <span>{translate('Observation')}</span>
                        <TextAreaComponent
                          text={observations}
                          setText={setObservations}
                        />
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </RegisterReadingsArea>
        </div>
        <DataBaseButtonArea>
          <CancelButtonComponent
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <AdvanceButtonStyled
            onClick={() => {
              handleSaveReading(type);
            }}
          >
            {' '}
            {translate('Save').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </DataBaseButtonArea>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
};
