import styled from 'styled-components';
import { Color, FontSize } from '../../../../../Styles/Styles';

export const GrapAsideMenu = styled.aside`
  width: 292px;
  height: 100%;
  background-color: ${Color.Brown3};
  overflow-y: auto;

  & li {
    list-style: none;
  }
`;

export const UlMenu = styled.ul`
  list-style: none;
  margin-top: 34px;
  user-select: none;
  margin-bottom: 64px;
`;

export const SpanTitle = styled.span`
  text-decoration: none;
  color: ${Color.Brown1};
  font-size: ${FontSize.Medium};
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const UlSubMenu = styled.ul`
  margin-top: 16px;
`;
